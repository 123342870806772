
enum ActionType {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  LANGUAGE = 'LANGUAGE',
  ADDNOTIFY = 'ADDNOTIFY',
  PRESETS = 'PRESETS'
}

type Action = {
  type: ActionType
  payload: any
}

const AppReducer = (state:any, action:Action) => {
    switch (action.type) {
      case 'LOGIN':
        return {
          ...state,
          profile: action.payload
        }
      case 'LOGOUT':
          return {
            ...state,
            profile: null
          }        
      case 'LANGUAGE':
        return {
          ...state,
          language: action.payload
        }
      case 'ADDNOTIFY':
        return {
          ...state,
          notifies: [...state.notifies, action.payload],
        }
      case 'PRESETS':
        return {
          ...state,
          presets: action.payload
        }
      default:
        return state;
    }
  };

  export type {Action}
  export  {ActionType}
  export default AppReducer
  