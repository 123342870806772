import React, { useRef, useEffect, useState, useContext } from "react";

import GlobeIcon from "../assets/icons/globeicon.svg";
import UserIcon from "../assets/icons/user.svg";
import MarkerIcon from "../assets/icons/marker.svg";
import BellIcon from "../assets/icons/bell.svg";
import BookMarkIcon from "../assets/icons/bookmark.svg";
import RightIcon from "../assets/icons/chevron-right.svg";
import PaperIcon from "../assets/icons/paper.svg";
import HandIcon from "../assets/icons/hand.svg";
import DBIcon from "../assets/icons/database.svg";
import ChainIcon from "../assets/icons/chain.svg";
import CheckboxIcon from "../assets/icons/check-square.svg";
import QuestionIcon from "../assets/icons/question.svg";

import { AppContext, Translator } from "../App";
import { ActionType } from "../redux/reduser";

interface IBarProps {
  onExpandSearch?: () => void;
  onExpandPanel?: (expanded: boolean) => void;
  onButtonClick?: (button: string) => void;
  onAddNewLand?: () => void;
}

const MainLeftBar = (props: IBarProps) => {
  const { state, dispatch } = useContext(AppContext);

  const { translate } = useContext(Translator);
  const [expanded, SetExpanded] = useState(false);

  useEffect(() => {
    if (props?.onExpandPanel) props?.onExpandPanel(expanded);
  }, [expanded]);

  const ToggleLang = (lang: string) => {
    dispatch({ type: ActionType.LANGUAGE, payload: lang });
  };

  return (
    <div
      style={{
        width: expanded ? 250 : 60,
        height: "100vh",
        position: "absolute",
        left: 0,
        top: 0,
        zIndex: 200,
        paddingTop: 20,
        paddingBottom: 20,
        textAlign: "center",
      }}
      className="controlDefBkg d-flex flex-column justify-content-between"
    >
      <div
        className="BarBtn d-flex align-items-center justify-content-center"
        onClick={() => {
          if (props.onExpandSearch) props.onExpandSearch();
        }}
        style={{ textDecoration: "none" }}
      >
        <img src={GlobeIcon} alt="" />
        {expanded ? (
          <span
            style={{ marginLeft: 10, fontWeight: "bold", fontSize: 20 }}
            className={"text-normal text-uppercase"}
          >
            {translate(state.language, "uselands")}
          </span>
        ) : (
          <></>
        )}
      </div>

      <div className="d-flex flex-column justify-content-start align-items-center h-100 pt-4 w-100">
        <div className="d-flex flex-column justify-content-between w-100">
          <div
            title={
              state.profile
                ? translate(state.language, "profile")
                : translate(state.language, "LOG IN/SIGN UP")
            }
            className={
              "BarBtn BarBtnLeftNavigation  d-flex align-items-center px-3 " +
              +(expanded ? "justify-content-start" : "justify-content-center")
            }
            style={{ textDecoration: "none", height: 50 }}
            onClick={() => {
              if (props?.onButtonClick && !state.profile)
                props?.onButtonClick("login");
              else if (props?.onButtonClick && state.profile)
                props?.onButtonClick("profile");
            }}
          >
            <img src={UserIcon} alt="" />
            {expanded && !state.profile ? (
              <span
                style={{ marginLeft: 10, fontSize: 16 }}
                className={"text-normal text-uppercase"}
              >
                {translate(state.language, "LOG IN/SIGN UP")}
              </span>
            ) : (
              <></>
            )}
            {expanded && state.profile ? (
              <span
                style={{ marginLeft: 10, fontSize: 16 }}
                className={"text-normal text-uppercase"}
              >
                {translate(state.language, "profile")}
              </span>
            ) : (
              <></>
            )}
          </div>

          {state.profile ? (
            <>
              <div
                title={translate(state.language, "add land")}
                className={
                  "BarBtn BarBtnLeftNavigation  d-flex align-items-center px-3 " +
                  (expanded
                    ? "justify-content-start"
                    : "justify-content-center")
                }
                style={{ textDecoration: "none", height: 50 }}
                onClick={() => {
                  if (props?.onAddNewLand) props?.onAddNewLand();
                }}
              >
                <img src={MarkerIcon} alt="" />
                {expanded ? (
                  <span
                    style={{ marginLeft: 10, fontSize: 16 }}
                    className={"text-normal text-uppercase"}
                  >
                    {translate(state.language, "add land")}
                  </span>
                ) : (
                  <></>
                )}
              </div>

              <div
                title={translate(state.language, "bell")}
                className={
                  "BarBtn BarBtnLeftNavigation  d-flex align-items-center px-3 " +
                  (expanded
                    ? "justify-content-start"
                    : "justify-content-center")
                }
                style={{ textDecoration: "none", height: 50 }}
              >
                <img src={BellIcon} alt="" />
                {expanded ? (
                  <span
                    style={{ marginLeft: 10, fontSize: 16 }}
                    className={"text-normal text-uppercase"}
                  >
                    {translate(state.language, "bell")}
                  </span>
                ) : (
                  <></>
                )}
              </div>

              <div
                title={translate(state.language, "Favorites")}
                className={
                  "BarBtn BarBtnLeftNavigation  d-flex align-items-center px-3 " +
                  (expanded
                    ? "justify-content-start"
                    : "justify-content-center")
                }
                style={{ textDecoration: "none", height: 50 }}
              >
                <img src={BookMarkIcon} alt="" />
                {expanded ? (
                  <span
                    style={{ marginLeft: 10, fontSize: 16 }}
                    className={"text-normal text-uppercase"}
                  >
                    {translate(state.language, "Favorites")}
                  </span>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <></>
          )}

          {state.profile ? (
            <div
              title={translate(state.language, "quit")}
              className={
                "BarBtn BarBtnLeftNavigation  d-flex align-items-center px-3 " +
                (expanded ? "justify-content-start" : "justify-content-center")
              }
              style={{ textDecoration: "none", height: 50 }}
              onClick={() => {
                localStorage.removeItem("tocken");
                localStorage.removeItem("user");
                dispatch({ type: ActionType.LOGOUT, payload: null });
              }}
            >
              <img src={BellIcon} alt="" />
              {expanded ? (
                <span
                  style={{ marginLeft: 10, fontSize: 16 }}
                  className={"text-normal text-uppercase"}
                >
                  {translate(state.language, "quit")}
                </span>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}

          <div
            title={translate(state.language, "English")}
            onClick={() => ToggleLang("EN")}
            className={
              "BarBtn BarBtnLeftNavigation d-flex align-items-center px-3 " +
              (expanded ? "justify-content-start" : "justify-content-center")
            }
            style={{
              textDecoration: "none",
              color: "black",
              height: 50,
              fontWeight: state.language == "EN" ? "bold" : "normal",
            }}
          >
            <span style={{ width: 30 }}>EN</span>
            {expanded ? (
              <span
                style={{ marginLeft: 10, fontSize: 16 }}
                className={"text-normal text-uppercase"}
              >
                {translate(state.language, "English")}
              </span>
            ) : (
              <></>
            )}
          </div>

          <div
            title={translate(state.language, "Espanol")}
            onClick={() => ToggleLang("ES")}
            className={
              "BarBtn BarBtnLeftNavigation  d-flex align-items-center px-3 " +
              (expanded ? "justify-content-start" : "justify-content-center")
            }
            style={{
              textDecoration: "none",
              color: "black",
              height: 50,
              fontWeight: state.language == "ES" ? "bold" : "normal",
            }}
          >
            <span style={{ width: 30 }}>ES</span>
            {expanded ? (
              <span
                style={{ marginLeft: 10, fontSize: 16 }}
                className={"text-normal text-uppercase"}
              >
                {translate(state.language, "Espanol")}
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div
          style={{ height: "1px", display: "block", width: "80%" }}
          className="my-4 separator"
        ></div>

        <div className="d-flex flex-column justify-content-between w-100">
          <div
            className={
              "BarBtn BarBtnLeftNavigation  d-flex align-items-center px-3 " +
              (expanded ? "justify-content-start" : "justify-content-center")
            }
            style={{ textDecoration: "none", height: 50 }}
          >
            <img src={PaperIcon} alt="" />
            {expanded ? (
              <span
                style={{ marginLeft: 10, fontSize: 16 }}
                className={"text-normal text-uppercase"}
              >
                {translate(state.language, "About project")}
              </span>
            ) : (
              <></>
            )}
          </div>

          <div
            className={
              "BarBtn BarBtnLeftNavigation  d-flex align-items-center px-3 " +
              (expanded ? "justify-content-start" : "justify-content-center")
            }
            style={{ textDecoration: "none", height: 50 }}
          >
            <img src={HandIcon} alt="" />
            {expanded ? (
              <span
                style={{ marginLeft: 10, fontSize: 16 }}
                className={"text-normal text-uppercase"}
              >
                {translate(state.language, "How to use")}
              </span>
            ) : (
              <></>
            )}
          </div>

          <div
            className={
              "BarBtn BarBtnLeftNavigation  d-flex align-items-center px-3 " +
              (expanded ? "justify-content-start" : "justify-content-center")
            }
            style={{ textDecoration: "none", height: 50 }}
          >
            <img src={DBIcon} alt="" />
            {expanded ? (
              <span
                style={{ marginLeft: 10, fontSize: 16 }}
                className={"text-normal text-uppercase"}
              >
                {translate(state.language, "Tariffs")}
              </span>
            ) : (
              <></>
            )}
          </div>

          <div
            className={
              "BarBtn BarBtnLeftNavigation  d-flex align-items-center px-3 " +
              (expanded ? "justify-content-start" : "justify-content-center")
            }
            style={{ textDecoration: "none", height: 50 }}
          >
            <img src={ChainIcon} alt="" />
            {expanded ? (
              <span
                style={{ marginLeft: 10, fontSize: 16 }}
                className={"text-reflink text-uppercase"}
              >
                {translate(state.language, "Referal link")}
              </span>
            ) : (
              <></>
            )}
          </div>

          <div
            className={
              "BarBtn BarBtnLeftNavigation  d-flex align-items-center px-3 " +
              (expanded ? "justify-content-start" : "justify-content-center")
            }
            style={{ textDecoration: "none", height: 50 }}
          >
            <img src={CheckboxIcon} alt="" />
            {expanded ? (
              <span
                style={{ marginLeft: 10, fontSize: 16 }}
                className={"text-normal text-uppercase"}
              >
                {translate(state.language, "Verification")}
              </span>
            ) : (
              <></>
            )}
          </div>

          <div
            className={
              "BarBtn BarBtnLeftNavigation  d-flex align-items-center px-3 " +
              (expanded ? "justify-content-start" : "justify-content-center")
            }
            style={{ textDecoration: "none", height: 50 }}
          >
            <img src={QuestionIcon} alt="" />
            {expanded ? (
              <span
                style={{ marginLeft: 10, fontSize: 16 }}
                className={"text-normal text-uppercase"}
              >
                {translate(state.language, "Help center")}
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <div
        onClick={() => SetExpanded(!expanded)}
        className={expanded ? "BarBtn px-3 rotate180" : "BarBtn px-3"}
      >
        <img src={RightIcon} alt="" />
      </div>
    </div>
  );
};

export default MainLeftBar;
