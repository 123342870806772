import { gql } from '@apollo/client'

let  Mutations = {
    REGISTERATION : gql`mutation TryRegister($email: String!, $password: String!) {
        register(email: $email, password: $password) {
           tocken
           user {
                id
                roles
                birthday
                avatar
                city {id}
                UserF
                UserI
                UserO
                phone
                phoneConfirmed
                email
                emailConfirmed
                address
                Company
                CompanyLocation
                registerDateTime
                lastVisitDateTime
                Actived
                areasCount
            }
        }
    }`,

    LOGIN : gql`mutation TryLogin($useroremail: String!, $password: String!) {
        login(useroremail: $useroremail, password: $password) {
           tocken
           user {
                id
                roles
                birthday
                avatar
                city {id}
                UserF
                UserI
                UserO
                phone
                phoneConfirmed
                email
                emailConfirmed
                address
                Company
                CompanyLocation
                registerDateTime
                lastVisitDateTime
                Actived
                areasCount
           }
        }
    }`,

    CHANGEPASSWORD : gql`mutation ChangePassword($email: String!) {
        changePasswordByEmail(email: $email)
    }`,

    CREATE_OR_UPDATE_LAND : gql`mutation CreateOrUpdateLand($land: AreasInput!) {
        createOrUpdateArea(land: $land)  
        {
            id
        }
    }`,

    REMOVE_LAND: gql`mutation RemoveLand($id: String!) {
        removeArea(id: $id) 
    }`,

    TOGGLE_LAND_ACTIVITY: gql`mutation ToggleLandActivity($id: String!, $newState: Boolean) {
        toggleArea(id: $id, newState: $newState) 
    }`,
}

export default Mutations