import {useState, useEffect, useContext} from "react"


import AppleIcon from "../assets/icons/apple.png"
import MicrsoftIcon from "../assets/icons/microsoft.png"
import GoogleIcon from "../assets/icons/google.png"
import EyeIcon from "../assets/icons/eye.svg";
import EyeOffIcon from "../assets/icons/eyeoff.svg";

import CloseIcon from "../assets/icons/close.svg";
import LeftIcon from "../assets/icons/chevronleft.svg";

import {Error} from "./LoginForm"

import {AppContext, Translator} from "../App"

import useProfile from "../hooks/useProfile"

import { emailValidator } from "../helpers/emailValidator";
import { ActionType } from "../redux/reduser";

interface IStartModal {
  formId: string
  open: boolean;
  onClose?: () => void;
  onBack?:(formId: string) => void;
  onLogin:()=>void
}

const SignupForm = (props: IStartModal) => {

    const {state : {language}, dispatch} = useContext(AppContext);
    const {translate} = useContext(Translator);

    const [email, SetEmail] = useState({error: "", value: ""})
    const [mode, SetMode] = useState("");
    const [pssword, SetPassword] = useState({error: "", value: ""});
    const [passview, SetPasswordView] = useState(false);
    
    const {error : profileError, TryLogin, TryRegister, loadMyProfile, profile} = useProfile({ onCompleted: (command: string, data:any) => {
      if (command == "register")
      {  
          let tocken = data.tocken
          let user = data.user
          localStorage.setItem("tocken", tocken)
          localStorage.setItem("user", JSON.stringify(user))
          
          dispatch({type :ActionType.LOGIN, payload: user})

          if (props?.onClose) props?.onClose();
      }
    } });

    useEffect(()=>{
        if (mode == "password")
        {
          if (pssword.value.length>=8)
          {
            SetPassword({...pssword, error: ""})
          } else 
          {
            SetPassword({...pssword, error: translate(language, "Your password must origin\nAt least 8 characters")})
          }
       }
    }, [pssword.value, mode])

    useEffect(()=>{
      let emailerror = emailValidator(email.value)
      SetEmail({...email, error: translate(language, emailerror) ?? "" })
    }, [email.value])

    useEffect(()=>{
      if (profileError=="User already exists.")
      {
        SetMode("password");
      }
    }, [profileError])

  return props?.open ? (
    <div className="modalBkg">
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: 510,
        }}
        onClick={() => {
          SetEmail({error: "", value: ""})
          SetPassword({error: "", value: ""})
          if (props?.onClose) props?.onClose();
        }}
      ></div>

      <div className="modalWindow" style={{maxWidth:550}}>
        <div className="modalHeader d-flex justify-content-between">
          <a href="javascript:void(0);" className="BarBtn" style={{width:30}} onClick={() => {
            if (props?.onBack) props?.onBack(props.formId)
          }}>
            <img src={LeftIcon} />
          </a>

          <a
            href="javascript:void(0);"
            className="BarBtn"
            style={{width:30}}
            onClick={() => {
              SetMode("");
              SetEmail({error: "", value: ""})
              SetPassword({error: "", value: ""})
              if (props?.onClose) props?.onClose();
            }}
          >
            <img src={CloseIcon} />
          </a>
        </div>
        <div className="modalBody">
          {mode == "" || mode == "password" ? <p style={{ fontSize: 32 }} className="text-uppercase">{translate(language, "create your account")}</p> : <></>}
          {mode == "verifyemail" ? <p style={{ fontSize: 32 }} className="text-uppercase">{translate(language, "verify your email")}</p> : <></>}

          {mode == "" || mode == "password" ? <p style={{ fontSize: 12 }}>
            {translate(language, "Note that phone verification may be required for sign up. Your number will only be used to verify your identity for security proposes")}{"."}
          </p> : <></>}

          {mode == "verifyemail" ? <p style={{ fontSize: 12 }}>
            {translate(language, "We sent an email to")}
            {" "}{email.value}{"."}
            {translate(language, "Click the link inside to get started")}{"."}
          </p> : <></>}

          {mode == "" || mode == "password" ? 
          <div className={"InputContainer d-flex flex-row justify-content-between align-items-center " + (email.error != "" ? "ErrorContainer" : "SuccessContainer")} style={{ padding: 0 }} >
        <input
          type="text"
          style={{
            background: "transparent",
            border: "none",
            paddingLeft: 10,
            paddingRight: 10,
            color: "black",
            width: "100%",
          }}
          placeholder={translate(language, "Email address")}
          value={email.value} 
          onChange={(e: any) => {
            SetEmail({...email, value: e.target.value});
          }}
        />
      </div> : <></>}

      <Error error={email.error} open={email.error != ""} />
      <Error error={profileError} open={profileError != ""} />

      <div className="separator-10"></div>

          {mode == "password" ? <>
            <div
              className={"InputContainer d-flex flex-row justify-content-between align-items-center " + (pssword.error ? "ErrorContainer" : "SuccessContainer")}
              style={{ padding: 0 }}
            >
              <input
                type={passview ? "text" : "password"}
                style={{
                  background: "transparent",
                  border: "none",
                  paddingLeft: 10,
                  paddingRight: 10,
                  color: "black",
                  width: "100%",
                }}
                placeholder={translate(language, "Password")}
                value={pssword.value}
                onChange={(e: any) => {
                  SetPassword({...pssword, value: e.target.value});
                }}
              />

              <img src={passview ? EyeOffIcon : EyeIcon} style={{width:20, height:20, marginRight:10, cursor:"pointer"}} onClick={()=>SetPasswordView(!passview)} />
            </div>
            <Error error={pssword.error} open={pssword.error != ""} />
            <div className="separator-10"></div>
            <div className="separator-10"></div>{" "}
          </> : <></>}

          {mode != "verifyemail" ?
          <button
            className="btn btn-primary w-100 me-1 d-flex align-items-center justify-content-center text-uppercase"
            style={{ height: 44 }}
            disabled={(email.error!="" || pssword.error!="" || profileError!="")}
            onClick={() => {
              if (mode == "") SetMode("password");
              if (mode == "password") 
                {
                  SetMode("verifyemail");
                  TryRegister({variables: {
                    email: email.value,
                    password: pssword.value
                  }});
                }
            }}
          >
            {translate(language, "continue")}
          </button> : <></>}

          

          {mode == "verifyemail" ? 
            <div className="InputContainer TransContainer border-1 d-flex flex-row justify-content-center align-items-center" style={{ padding: 0 }}>
            <img
              src={GoogleIcon}
              style={{ width: 25, height: 25, marginLeft: 10 }}
            />
            <a
              href="https://mail.google.com"
              target="_blank"
              style={{
                background: "transparent",
                border: "none",
                paddingLeft: 10,
                paddingRight: 10,
                color: "black"
              }}
              className="text-decoration-none text-lightmode"
            >
              {translate(language, "Open Gmail")}
            </a>
          </div>
          : <></>}

          <div className="separator-10"></div>

          {mode == "" || mode == "password" ? 
          <small>
            {translate(language, "Already have an account")}{"? "}
            <a href="javascript:void(0)" className="text-capitalize" style={{ color: "#B407F4" }} onClick={()=>
            {
              if (props?.onLogin) props?.onLogin();
            }}>
              {translate(language, "log in")}
            </a>
          </small> : <></>}

          {mode == "verifyemail" ?
          <small>
            <a href="javascript:void(0)" className="text-capitalize" style={{ color: "#B407F4" }} onClick={()=>
            { 
                alert("Resend email")
            }}>
                {translate(language, "resend email")}
            </a>
          </small> : <></>}

          <div className="separator-10"></div>
          
          {mode == "" ? <>
          <div style={{position:"relative", marginTop:20, marginBottom:25, height:20}} className="d-flex align-items-center justify-content-center">
            <div className="divider-1" style={{zIndex:100, width:"100%", display:"block", padding:0}}></div>
            <span style={{position:"absolute", zIndex:101, background:"white",width:40, padding:0}} className="text-uppercase">{translate(language, "or")}</span>
          </div>

          <div className="separator-10"></div>
          
          <div
            className="InputContainer TransContainer border-1 d-flex flex-row justify-content-between align-items-center"
            style={{ padding: 0 }}
          >
            <img
              src={GoogleIcon}
              style={{ width: 25, height: 25, marginLeft: 10 }}
            />
            <a
              href=""
              style={{
                background: "transparent",
                border: "none",
                paddingLeft: 10,
                paddingRight: 10,
                color: "black",
                width: "100%",
                textAlign: "left",
              }}
              className="text-decoration-none text-lightmode"
            >
              {translate(language, "Continue with Google")}
            </a>
          </div>

          <div className="separator-10"></div>

          <div
            className="InputContainer TransContainer border-1 d-flex flex-row justify-content-between align-items-center"
            style={{ padding: 0 }}
          >
            <img
              src={MicrsoftIcon}
              style={{ width: 25, height: 25, marginLeft: 10 }}
            />
            <a
              href=""
              style={{
                background: "transparent",
                border: "none",
                paddingLeft: 10,
                paddingRight: 10,
                color: "black",
                width: "100%",
                textAlign: "left",
              }}
              className="text-decoration-none text-lightmode"
            >
              {translate(language, "Continue with Microsoft Account")}
            </a>
          </div>

          <div className="separator-10"></div>

          <div
            className="InputContainer TransContainer border-1 d-flex flex-row justify-content-between align-items-center"
            style={{ padding: 0 }}
          >
            <img
              src={AppleIcon}
              style={{ width: 25, height: 25, marginLeft: 10 }}
            />
            <a
              href="#"
              style={{
                background: "transparent",
                border: "none",
                paddingLeft: 10,
                paddingRight: 10,
                color: "black",
                width: "100%",
                textAlign: "left",
              }}
              className="text-decoration-none text-lightmode"
            >
              {translate(language, "Continue with Apple")}
            </a>
          </div>
          </> : <></>}

          <div className="separator-50"></div>

          <div
            className="w-100 text-lightmode"
            style={{ fontSize: 13, marginLeft: 10 }}
          >
            <a
              href=""
              style={{ marginRight: 10 }}
              className="text-decoration-none text-lightmode"
            >
              {translate(language, "Terms of use")}
            </a>{" "}
            |{" "}
            <a
              href=""
              style={{ marginLeft: 10 }}
              className="text-decoration-none text-lightmode"
            >
              {translate(language, "Privacy policy")}
            </a>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default SignupForm;
