import { gql } from '@apollo/client'

let Queries = {
    COUNTRIES : gql`query CountriesList($page: Int!, $itemsperpage: Int!) {
        countries(page: $page, itemsperpage: $itemsperpage) {
            id
            icon
            title_ru
            actived
        }
    }`,
    ME : gql`query MyData {
        me {
            id
            roles
            birthday
            avatar
            city {id}
            UserF UserI UserO phone phoneConfirmed
            email emailConfirmed address gender Company CompanyLocation
            favorites {id}
            lands {
                id
                tags_eng tags_es description_eng description_es
                fragments 
                createdDate updatedDate likes 
                cost
                paydScheduling
                lastActivityDate
                Actived
            }
            registerDateTime lastVisitDateTime Actived Admin areasCount
        }
    }`,
    PRESETS : gql`query Presets($names: [String]) { presets(names: $names) {id name value} }`

}

export default Queries