import React, { useRef, useEffect, useState } from 'react';

import GlobeIcon from "../assets/icons/globeicon.svg"
import UserIcon from "../assets/icons/user.svg"
import MarkerIcon from "../assets/icons/marker.svg"
import BellIcon from "../assets/icons/bell.svg"
import BookMarkIcon from "../assets/icons/bookmark.svg"
import RightIcon from "../assets/icons/chevron-right.svg"
import PaperIcon from "../assets/icons/paper.svg"
import HandIcon from "../assets/icons/hand.svg"
import DBIcon from "../assets/icons/database.svg"
import ChainIcon from "../assets/icons/chain.svg"
import CheckboxIcon from "../assets/icons/check-square.svg"
import QuestionIcon from "../assets/icons/question.svg"
import SearchIcon from "../assets/icons/search.svg"
import CubeIcon from "../assets/icons/cube.svg"
import StarIcon from "../assets/icons/star.svg"

interface IQuiclBarProps
{
    open: boolean
    displaysment: number
    onExpandSearch?:(tab:string)=>void
    onRandomSearch?:()=>void
}

const QuickBar = (props: IQuiclBarProps) =>{
    const [expanded, SetExpanded] = useState(false);

    return <div style={{width:60, height: "auto", position:"absolute", left: props?.open ?  props?.displaysment : -70, top:0, zIndex:200, paddingTop:20, paddingBottom:20, textAlign:"center", borderBottomLeftRadius:15, borderBottomRightRadius:15}} className="controlDefBkg d-flex flex-column justify-content-between">

        
                <div className="d-flex flex-column justify-content-start align-items-center h-100">
                    <div className="d-flex flex-column justify-content-between">
                        <a href="javascript:void(0)" className="BarBtn pb-4 p-1" onClick={()=>
                        {
                            if (props.onExpandSearch) props.onExpandSearch("expand")
                        }}>
                            <img src={RightIcon}  />
                        </a>

                        <a href="javascript:void(0)" className="BarBtn btn btn-menu p-1 mb-4" onClick={()=>
                        {
                            if (props.onExpandSearch) props.onExpandSearch("search")
                        }}>
                            <img src={SearchIcon} />
                        </a>

                        <a href="javascript:void(0)" className="BarBtn btn btn-menu p-1 mb-4" onClick={()=>
                        {
                            if (props.onRandomSearch) props.onRandomSearch()
                        }}>
                            <img src={CubeIcon} />
                        </a>

                        <a href="javascript:void(0)" className="BarBtn btn btn-menu p-1" onClick={()=>
                        {
                            if (props.onExpandSearch) props.onExpandSearch("favorites")
                        }}>
                            <img src={StarIcon} />
                        </a>
                    </div>
                </div>
    </div>
}

export default QuickBar