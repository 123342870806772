import { useState, useContext } from "react";

import CloseIcon from "../assets/icons/close.svg"

import {AppContext, Translator} from "../App"
interface IStartModal
{
    open: boolean
    onClose?: ()=>void
    onLogin?: ()=>void
    onSignup?: ()=>void
}

const StartForm = (props:IStartModal) => {
    
    const {state: {language}, dispatch} = useContext(AppContext);
    const {translate} = useContext(Translator);

    return props?.open ? <div className="modalBkg">
        <div style={{width:"100%", height:"100%", position:"absolute", zIndex:510}} onClick={()=>{
            if (props?.onClose) props?.onClose()                 
        }}></div>

        <div className="modalWindow">
            <div className="modalHeader d-flex justify-content-between">
                <span></span>
                <a href="javascript:void(0);" className="BarBtn" style={{width:30}} onClick={()=>{
                        if (props?.onClose) props?.onClose()                 
                    }} >
                        <img src={CloseIcon} />
                </a>  
            </div>
            <div className="modalBody">
                <p style={{fontSize:32}} className="text-uppercase">{translate(language, "get started")}</p>
                <p style={{fontSize:12}}>{translate(language, "Registration opens up the opportunity to use all the functionality of the service")}</p>

                <div className="d-flex justify-content-between">
                    <a href="javascript:void(0);" className="btn btn-primary w-100 me-1 d-flex align-items-center justify-content-center text-uppercase" style={{height:44}} onClick={()=>
                    {
                        if (props?.onLogin) props?.onLogin()
                    }}>{translate(language, "log in")}</a>
                    <a href="javascript:void(0);" className="btn btn-primary w-100 ms-1 d-flex align-items-center justify-content-center   text-uppercase" style={{height:44}} onClick={()=>
                    {
                        if (props?.onSignup) props?.onSignup()
                    }}>{translate(language, "sign up")}</a>
                </div>
            </div>
        </div>

    </div> : <></>
}

export default StartForm