import React, { useEffect, useState } from 'react'
import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client'

import {
    GraphqlMutations,
    GraphqlQueries,
    GraphqlSubscriptions
  } from "../graphql"

const usePresets = ({onCompleted}:{onCompleted?: any}) => {

  const [error, SetError] = useState("")
  const [presets, SetPresets] = useState([])

  const [loadPresets] = useLazyQuery(GraphqlQueries.PRESETS, {
    fetchPolicy: 'no-cache',
    onCompleted: result => {
      SetPresets(result?.presets ?? [])
       if (onCompleted) onCompleted("presets", result?.presets ?? [])
    },
    onError: (error) => {
       SetError(error.message)
       setTimeout(()=>SetError(""), 5000)
    }
  })

  return { error, loadPresets, presets }
}

export default usePresets