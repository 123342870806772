import en from "./en"
import es from "./es"

export default {
    localization: {
        "EN":en,
        "ES":es
    },
    translate(lang:string, key:string) 
    {
        if (lang?.toUpperCase() == "EN") return en[key]
        else if (lang?.toUpperCase() == "ES") return es[key]
        else return "no translation"
    }
}