import React, {
  useRef,
  useEffect,
  useState,
  createContext,
  useReducer,
} from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import { ApolloProvider } from "@apollo/client";

import apiClient from "./graphql/apolloProvider";

import MainLayout from "./Mainscreen/mainscreen";

import langs from "./localize/langs";

import AppReducer, { Action, ActionType } from "./redux/reduser";
import { initialValues, UseLandsAppState } from "./redux/AppContext";
import { stat } from "fs";

interface DefaultContextValues {
  state: UseLandsAppState;
  dispatch: (action: Action) => void;
}

const AppContext = createContext<DefaultContextValues>({
  state: initialValues,
  dispatch: (action: Action) => {},
});

const Translator = createContext(langs);

function App() {
  const [state, dispatch] = useReducer(AppReducer, initialValues);

  useEffect(() => {
    if (localStorage.getItem("user") && state.profile == null) {
      dispatch({
        type: ActionType.LOGIN,
        payload: JSON.parse(localStorage.getItem("user") ?? ""),
      });
    }
  }, [state]);

  return (
    <div className="App">
      <ApolloProvider client={apiClient}>
        <AppContext.Provider value={{ state, dispatch }}>
          <Translator.Provider value={langs}>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<MainLayout />}></Route>
                  <Route path="/newuser/:id" element={<MainLayout />}></Route>
                  <Route path="*" element={<MainLayout />}></Route>
                </Routes>
              </BrowserRouter>
          </Translator.Provider>
        </AppContext.Provider>
      </ApolloProvider>
    </div>
  );
}

export default App;
export { AppContext, Translator };
