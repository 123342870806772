import { useContext, useReducer, createContext } from "react";
import { Action } from "./reduser";
import { UserProfile } from "../models";
import { Area } from "../models/lands";

type UseLandsAppState = {
  searchInput: boolean;
  profile: UserProfile | null;
  tocken: string;
  currentDate: Date;
  country: string;
  countryCode: string;
  dataStore: any;
  language: string;
  notifies: string[];
  presets: string[];
  lands:Area[];
};

let initialValues: UseLandsAppState = {
  searchInput: false,
  profile: null,
  tocken: localStorage.getItem("tocken") ?? "",
  currentDate: new Date(),
  country: "ru",
  countryCode: "",
  dataStore: null,
  language: "EN",
  notifies: [],
  presets: [],
  lands: [],
};

const AppContext = createContext({
  state: initialValues,
  dispatch: (action: Action) => {},
});

let useAppContext = () => useContext(AppContext);

export default AppContext;
export { initialValues, useAppContext };
export type { UseLandsAppState };
