import React, { useRef, useEffect, useState } from 'react';

import CubeIcon from "../assets/icons/cube.svg"
import RightIcon from "../assets/icons/chevron-right.svg"
import SearchIcon from "../assets/icons/search.svg"
import MicroIcon from "../assets/icons/ph_microphone.svg"


interface ISearchProps
{
    open: boolean
    displaysment: number
    onClose?: ()=>void
    onSearchText?: (text:string)=>void
    onRandomClick?: ()=>void
}

const SearchBar = (props: ISearchProps) =>{

    const [search, SetSearch] = useState("")

    useEffect(()=>{
        if (props.onSearchText)
        {
            props.onSearchText(search)
        }
    }, [search])

    return <div style={{width:370, height: 80, position:"absolute", left:(props.open ? props.displaysment : -480), top:10, zIndex:200, textAlign:"center", paddingTop:10}} className="controlDefBkg rounded">
        <div className="d-flex flex-row justify-content-between w-100">

            <button type="button" className="btn btn-menu-solid rotate180 position-absolute d-flex align-items-center justify-content-center" style={{width:40, height:40, marginLeft:0, right:-50, top:0}} onClick={()=>{ if (props.onClose) props.onClose() }}>
                <img src={RightIcon} />
            </button>  

            <div className="SearchInput d-flex flex-row justify-content-between align-items-center" style={{marginLeft:15, marginRight:5, padding:0}}>
                <input type="text" style={{background: "transparent", border:"none", paddingLeft:10, width:180}} placeholder="Enter key words" value={search} onChange={(e:any)=>{
                    SetSearch(e.target.value)
                }} />
                <a href="javascript:void(0)" className="BarBtn p-2" >
                    <img src={SearchIcon} />
                </a>  
            </div>
            <a href="javascript:void(0)" className="BarBtn p-2" style={{width:45, height:45, marginRight:5}} onClick={()=>{ if (props.onRandomClick) props.onRandomClick() }}>
                <img src={CubeIcon} />
            </a>  
        </div>
        <div className="d-flex flex-row justify-content-between w-100 align-items-center">
            <span style={{width:45}}></span>
            <p className="helpword text-light-6">fashion</p>
            <p className="helpword text-light-6">fashion</p>
            <p className="helpword text-light-6">fashion</p>
            <p className="helpword text-light-6">fashion</p>
            <span style={{width:45}}></span>
        </div>
    </div>
}

export default SearchBar