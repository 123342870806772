import React, { useEffect, useState } from 'react'
import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client'

import {
    GraphqlMutations,
    GraphqlQueries,
    GraphqlSubscriptions
  } from "../graphql"

import {Area, Polygon, Point, Comment, AreaLayers} from "../models/lands"

const useLands = ({onCompleted}) => {

  const [error, SetError] = useState("")
  const [lands, SetLands] = useState([])

  const [loadMyLands] = useLazyQuery(GraphqlQueries.ME, {
    fetchPolicy: 'no-cache',
    onCompleted: result => {
      SetLands(result?.me?.lands ?? [])
       if (onCompleted) onCompleted("lands", result?.me?.lands ?? [])
       //dispatch({type: ActionType.UPLOADDSLIST, payload: result?.adslist?.items ?? []})
    },
    onError: (error) => {
       SetError(error.message)
       setTimeout(()=>SetError(""), 5000)
    }
  })

  const [CreateOrUpdateLand] = useMutation(GraphqlMutations.CREATE_OR_UPDATE_LAND, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
        if (onCompleted) onCompleted("land", data.createOrUpdateArea)
    },
    onError: (error) => {
        SetError(error.message)
        setTimeout(()=>SetError(""), 5000)
    }
  });

  const [RemoveLand] = useMutation(GraphqlMutations.REMOVE_LAND, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
        if (onCompleted) onCompleted("removeArea", data.removeArea)
    },
    onError: (error) => {
        SetError(error.message)
        setTimeout(()=>SetError(""), 5000)
    }
  });

  const [ToggleLandActivity] = useMutation(GraphqlMutations.TOGGLE_LAND_ACTIVITY, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
        if (onCompleted) onCompleted("toggleArea", data.toggleArea)
    },
    onError: (error) => {
        SetError(error.message)
        setTimeout(()=>SetError(""), 5000)
    }
  });


  return {error, loadMyLands, CreateOrUpdateLand, RemoveLand, ToggleLandActivity, lands}
}

export default useLands