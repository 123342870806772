const es: { [name: string]: string } = {
    "uselands" : "tierras de uso",
    "LOG IN/SIGN UP" : "ACCESO",
    "log in" : "acceso",
    "sign up" : "inscribirse",
    "add land": "agregar tierra",
    "bell": "campana",
    "profile" : "perfil",
    "My lands" : "Mis tierras",
    "Notifications" : "Notificaciones",
    "Favorites" : "el favorito",
    "English" : "inglés",
    "Espanol" : "Español",
    "About project" : "Acerca del proyecto",
    "How to use" : "Cómo utilizar",
    "Tariffs" : "Tarifas",
    "Referal link" : "Enlace de referencia",
    "Verification" : "Verificación",
    "Help center" : "Centro de ayuda",
    "Order your land" : "Ordena tu terreno",
    "Close" : "Cerca",
    "get started" : "Empezar",
    "Registration opens up the opportunity to use all the functionality of the service" : "El registro abre la oportunidad de utilizar todas las funciones del servicio.",
    "welcome back" : "bienvenido de nuevo",
    "Enter your Password" : "Ingresa tu contraseña",
    "Reset your password" : "Restablecer su contraseña",
    "Enter your address and we will send you instructions to reset your password" : "Ingresa tu dirección y te enviaremos instrucciones para restablecer tu contraseña",
    "check your email" : "consultar su correo electrónico",
    "Please check the email address" : "Por favor verifique la dirección de correo electrónico",
    "for instructions to reset your password" : "para obtener instrucciones para restablecer su contraseña",
    "Email address" : "Dirección de correo electrónico",
    "Password" : "Contraseña",
    "Forgot password" : "Has olvidado tu contraseña",
    "resend email" : "reenviar email",
    "continue" : "continuar",
    "Don’t have an account" : "No tienes una cuenta",
    "Sign Up" : "Inscribirse",
    "Back" : "Atrás",
    "or" : "o",
    "Continue with Google" : "Continuar con Google",
    "Continue with Microsoft Account" : "Continuar con la cuenta de Microsoft",
    "Continue with Apple" : "Continuar con Apple",
    "Terms of use" : "Condiciones de uso",
    "Privacy policy" : "Política de privacidad",
    "create your account" : "Crea tu cuenta",
    "verify your email" : "Verifique su correo electrónico",
    "Note that phone verification may be required for sign up. Your number will only be used to verify your identity for security proposes" : "Tenga en cuenta que es posible que se requiera verificación telefónica para registrarse. Su número solo se utilizará para verificar su identidad por motivos de seguridad",
    "We sent an email to" : "Le enviamos un correo electrónico a",
    "Click the link inside to get started" : "Haga clic en el enlace interior para comenzar",
    "Open Gmail" : "Abrir Gmail",
    "Already have an account" : "Ya tienes una cuenta",
    "Choose a pieces of land" : "Elige un terreno",
    "Zoom in on the planet and select the number of tiles you need on the piece of land you like": "Haz zoom en el planeta y selecciona la cantidad de mosaicos que necesitas en el terreno que te guste",
    "Start my free trial" : "Iniciar mi prueba gratuita",
    "Click on the land tile to select": "Haga clic en el mosaico de tierra para seleccionar",
    "Click on the tile again to remove it": "Haga clic en el mosaico nuevamente para eliminarlo.",
    "Today - Free trail for 30 days. Cancel anytime": "Hoy: recorrido gratuito durante 30 días. Cancelar en cualquier momento",
    "We'll send you reminder 7 days before free trail ends": "Le enviaremos un recordatorio 7 días antes de que finalice el recorrido gratuito",
    "Your subscription starts, unless you've canceled during the trail": "Tu suscripción comienza, a menos que la hayas cancelado durante el recorrido.",
    "Try USELANDS for free": "Prueba USELANDS gratis",
    "Welcome to Uselands": "Bienvenido a Useland",
    "Next": "Próximo",
    "Not Right now" : "No ahora",
    "Go to editing a plot of land": "Ir a editar un terreno",
    "Yearly": "Anual",
    "Every Six Months": "Cada seis meses",
    "Monthly": "Mensual",
    "Free 30 day trail, cancel any time": "Ruta gratuita de 30 días, cancela en cualquier momento",
    "Credit / Debit Card" : "Tarjeta de crédito / débito",
    "Pay with bonuses from my account" : "Pagar con bonos de mi cuenta",
    "Contact information": "Información del contacto",
    "Card Number" : "Número de tarjeta",
    "Expiration Date": "Fecha de caducidad",
    "Country": "País",
    "Zip Code": "Código postal",
    "Cardholder’s Name": "Nombre del tarjetahabiente",
    "Procesing" : "Procesando",
    "Cost per one tile of land" : "Costo por una loseta de tierra",
    "Payment method" : "Método de pago",
    "Subscription period" : "Periodo de suscripción",
    "Buy land" : "Comprar terreno",
    "Congratulations!" : "¡Felicidades!",
    "required field": "campo obligatorio",
    "Enter the correct email address": "Ingrese la dirección de correo electrónico correcta",
    "Your password must origin\nAt least 8 characters": "Su contraseña debe tener origen\nAl menos 8 caracteres",
    "quit": "salir",
}

export default es