import React, { useEffect, useState } from 'react'
import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client'

import {
    GraphqlMutations,
    GraphqlQueries,
    GraphqlSubscriptions
  } from "../graphql"


const useProfile = ({onCompleted, onError}) => {

  const [error, SetError] = useState("")
  const [profile, SetProfile] = useState(null)

  const [loadMyProfile] = useLazyQuery(GraphqlQueries.ME, {
    fetchPolicy: 'no-cache',
    onCompleted: result => {
       SetProfile(result?.me ?? null)
       if (onCompleted) onCompleted("profile", result.me)
       //dispatch({type: ActionType.UPLOADDSLIST, payload: result?.adslist?.items ?? []})
    },
    onError: (error) => {
       SetError(error.message)
       setTimeout(()=>SetError(""), 5000)
       if (onError) onError("profile", error.message)
    }
  })

  const [TryLogin] = useMutation(GraphqlMutations.LOGIN, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
        if (onCompleted) onCompleted("login", data.login)
    },
    onError: (error) => {
        SetError(error.message)
        setTimeout(()=>SetError(""), 5000)
        if (onError) onError("login", error.message)
    }
  });

  const [TryRegister] = useMutation(GraphqlMutations.REGISTERATION, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
        if (onCompleted) onCompleted("register", data.register)
    },
    onError: (error) => {
        SetError(error.message)
        setTimeout(()=>SetError(""), 5000)
        if (onError) onError("register", error.message)
    }
  });

  const [TryChangePassword] = useMutation(GraphqlMutations.CHANGEPASSWORD, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
        if (onCompleted) onCompleted("changePassword", data.changePasswordByEmail)
    },
    onError: (error) => {
        SetError(error.message)
        setTimeout(()=>SetError(""), 5000)
        if (onError) onError("changePassword", error.message)
    }
  });


  return {error, TryLogin, TryRegister, TryChangePassword, loadMyProfile, profile}
}

export default useProfile