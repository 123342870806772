import { useState, useEffect, forwardRef } from "react";
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import Dropdown from 'react-bootstrap/Dropdown';
import Badge from 'react-bootstrap/Badge';
import CloseIcon from "../assets/icons/close.svg";

import testAvatar from "../assets/icons/avatar.png"
import SmileIcon from "../assets/icons/smileIcon.png"
import LikeIcon from "../assets/icons/like.svg"
import MoreIcon from "../assets/icons/morevertical.svg";
import TestBkg from "../assets/icons/testbkg2.png"
import BookmarkIcon from "../assets/icons/bookmarkIcon.svg"
import BlockIcon from "../assets/icons/blockIcon.svg"
import FlagIcon from "../assets/icons/flagIcon.svg"

import LeftIcon from "../assets/icons/chevronleft.svg"
import RightIcon from "../assets/icons/chevron-right.svg"
import VolumeIcon from "../assets/icons/volumex.svg"

import { InfoCircle } from "react-bootstrap-icons";

import { Error } from "./LoginForm";

import {CommentItem} from "../models/CommentItem"

interface ICommentsModal {
  open: boolean;
  onClose?: () => void;
}

interface IReportForm
{
  open: boolean;
  onClose?: () => void;
}

const CustomToggle = ({ children, onClick }: {children:any, onClick:any}) => (
    <a
      href=""
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  )

  
const Comment = ({item, index, onReportClick, onSaveClick, onBlockClick}:
  {item: CommentItem, index: number, onReportClick: (item: CommentItem)=>void, onSaveClick: (item: CommentItem)=>void, onBlockClick: (item: CommentItem)=>void}) => {

    
    return <div className="w-100 p-3" style={{borderTop: index>0 ? "1px solid #D7D9D9" : ""}}>
            <div className="d-flex align-items-center justify-content-between w-100 mb-2">
                    <div className="d-flex align-items-top justify-content-start">
                        <img src={testAvatar} style={{width:32, height:32, marginRight:5}} className="rounded-circle" />
                        
                        <div className="d-flex flex-column align-items-start" style={{marginLeft:5}}>
                            <div className="d-flex w-100 justify-content-between align-items-center">
                                <span style={{color:"black", fontSize:13, fontWeight:"semibold", marginBottom:5}}>Mark Tomposn</span>
                                <span className="text-muted" style={{fontSize:10, fontWeight:"semibold", marginBottom:5}}>15 min</span>
                            </div>
                            <div className="commentText">
                                Exploring distant galaxies and stars is humanity's age-old dream. Today advanced technology enables us to reach for the cosmos, uncovering cosmic wonders and answering profound questions. The final frontier continues to inspire generations.  See translation
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center text-light-6" style={{fontSize:13}}>
                        
                    </div>
            </div>
            <div className="d-flex align-items-center justify-content-between w-100" style={{paddingLeft:41}}>
                    <a href="javascript:void(0)" className="BarBtn text-decoration-none text-muted" style={{fontSize:12}} >
                        Reply
                    </a>
                    <div className="d-flex align-items-center text-light-6" style={{fontSize:13}}>
                        <span style={{ marginRight:5}}>150</span>
                        <img src={LikeIcon} style={{width: 18, height:18, marginRight:5}} />
                        <Dropdown align={"end"}>
                            <Dropdown.Toggle  as={CustomToggle}  id="dropdown-basic">
                                <img src={MoreIcon} style={{width: 18, height:18}} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu align={"end"} style={{marginLeft:-140}} flip={true}>
                                <Dropdown.Item href="javascript:void(0);" onClick={()=>{
                                  if (onSaveClick) onSaveClick(item)
                                }}><img src={BookmarkIcon} style={{width: 18, height:18, marginRight:5}} /> Save</Dropdown.Item>

                                <Dropdown.Item href="javascript:void(0);" onClick={ ()=>{
                                  if (onBlockClick)  onBlockClick(item)
                                }}><img src={BlockIcon} style={{width: 18, height:18, marginRight:5}} /> Block</Dropdown.Item>

                                <Dropdown.Item href="javascript:void(0);" onClick={ ()=> {
                                  if (onReportClick) onReportClick(item)
                                }}><img src={FlagIcon} style={{width: 18, height:18, marginRight:5}} /> Report</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
            </div>
    </div>
}

const ReportItem = ({title, children, checked, onSelect}:{title: string, children:any, checked: boolean, onSelect:()=>void}) => {
  return <div className="d-flex align-items-top" onClick={()=>onSelect()} style={{cursor:"pointer"}}>
    <div style={{width:20, height:20, borderRadius:15, display:"block", border:"1px solid black", background: checked ? "black" : "white", marginRight:10, marginTop:3}}></div>
    <div className="d-flex flex-column align-items-start w-100">
      <b>{title}</b>
      <p style={{textAlign:"justify"}}>
        {children}
      </p>
    </div>
  </div>
}

const ReportForm = (props: IReportForm) => {
  
  var [chvar, SetSelected] = useState(0)

 useEffect(()=>{
    
  }, [])

  return props?.open ? (
    <div className="modalBkg">
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: 850,
        }}
        onClick={() => {
          if (props?.onClose) props?.onClose();
        }}
      ></div>
      <div className="modalWindow" style={{zIndex:"900", padding:0}}>
          <div className="modalHeader d-flex justify-content-between p-4">
            <div></div>
            <a
              href="javascript:void(0);"
              className="BarBtn"
              onClick={() => {
                if (props?.onClose) props?.onClose();
              }}
            >
              <img src={CloseIcon} />
            </a>
        </div>
        <div className="modalBody b-block h-100" style={{maxWidth:550, textAlign:"center"}}>
            <h4 className="mb-3">
              What type of issue are you reporting?
            </h4>
            <div className="d-block px-5" style={{maxHeight:400, overflow:"auto", borderBottom:"1px solid gray"}}>
            <ReportItem title={"Hate"} checked={chvar==1} onSelect={()=>SetSelected(chvar == 1 ? 0 : 1)}>
              Slurs, Racist or sexist stereotypes, Dehumanization, Incitement of fear or discrimination, Hateful references, Hateful symbols & logos
            </ReportItem>
            <ReportItem title={"Abuse & Harassment"} checked={chvar==2} onSelect={()=>SetSelected(chvar == 2 ? 0 :2)}>
              Insults, Unwanted Sexual Content & Graphic Objectification, Unwanted NSFW & Graphic Content, Violent Event Denial, Targeted Harassment and Inciting Harassment
            </ReportItem>
            <ReportItem title={"Violent Speech"} checked={chvar==3} onSelect={()=>SetSelected(chvar == 3 ? 0 :3)}>
              Violent Threats, Wish of Harm, Glorification of Violence, Incitement of Violence, Coded Incitement of Violence
            </ReportItem>
            </div>

            <div className="d-block px-5">
              <button  className="btn w-100 mt-2 mb-4" style={{ height: 44, background:"black", color: "white" }}
              disabled = {chvar==0}
              onClick={() => {}}>
              SUBMIT
              </button>
            </div>
        </div>

      </div>
      </div>) : (<></>);
}

const CommentsForm = (props: ICommentsModal) => {
  const [email, SetEmail] = useState("");
  const [mode, SetMode] = useState("");
  const [error, SetError] = useState("Wrong email or password");
  const [pssword, SetPassword] = useState("");
  const [passview, SetPasswordView] = useState(false);
  const [reportopen, SetReportShow] = useState(false)
  const [newcomment, SetNewComment] = useState("")

  const [comments, SetComments] = useState<CommentItem[]>([])

  useEffect(()=>{
    SetComments([{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}])
  }, [])

  const onBlockClick = (item: CommentItem)=>{

  }

  const onSaveClick = (item: CommentItem)=>{

  }

  const onReportClick = (item: CommentItem)=>{
    SetReportShow(true)
  }
  
  return props?.open ? (<>
    <div className="modalBkg">
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: 510,
        }}
        onClick={() => {
          if (props?.onClose) props?.onClose();
        }}
      ></div>

      <div className="modalWindow" style={{width: "75%", height:"95%", padding:0}}>
        <div className="modalBody d-flex h-100">
            <div className="photosList w-100">
                <img src={TestBkg}  />    
                <div style={{position:"absolute", zIndex:100}} className="d-flex justify-content-between w-100 px-2">
                    <button type="button" className="btn btn-menu-solid p-0 d-flex align-items-center justify-content-center" onClick={()=>{  }}>
                        <img src={LeftIcon} style={{width:20, height:20}} />
                    </button>  

                    <button type="button" className="btn btn-menu-solid rotate180 p-0 d-flex align-items-center justify-content-center" onClick={()=>{  }}>
                        <img src={LeftIcon} style={{width:20, height:20}} />
                    </button>  
                </div>

                <div style={{position:"absolute", zIndex:100, top:10}} className="d-flex align-items-center">
                    <button type="button" className="btn btn-menu-solid p-0 d-flex align-items-center justify-content-center" onClick={()=>{  }}>
                        <img src={VolumeIcon} style={{width:25, height:25}} />
                    </button>  
                    <Badge bg="secondary">Audio is muted</Badge>
                </div>
            </div>
            <div className="d-flex flex-column" style={{width:480}}>
                <div className="commentsList h-100">
                    {comments?.map((item: CommentItem, index: number)=>{
                        return <Comment item={item} index={index} onBlockClick={onBlockClick} onSaveClick={onSaveClick} onReportClick={onReportClick}></Comment>
                    })}
                </div>
                <div className="p-2" style={{borderTop:"1px solid #D7D9D9"}}>
                    <div className="d-flex flex-row justify-content-between align-items-center form-control" style={{height:42}}>
                        <a href="javascript:void(0)"  >
                            <img src={SmileIcon} style={{width:24, height:24}} />
                        </a>  
                        <input type="text" style={{background: "transparent", border:"none", paddingLeft:10, width:"100%", color: "black"}} placeholder="Add a comment..." value={newcomment} onChange={(e:any)=>{
                            SetNewComment(e.target.value)
                        }} />
                        <a href="javascript:void(0)" className="BarBtn p-2 text-decoration-none text-dark" style={{fontSize:12}} >
                            Post
                        </a>  
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <ReportForm open={reportopen} onClose={()=>SetReportShow(false)}></ReportForm>
  </>) : (
    <></>
  );
};

export default CommentsForm;
