import { useState, useEffect } from "react";

import AppleIcon from "../assets/icons/apple.png";
import MicrsoftIcon from "../assets/icons/microsoft.png";
import GoogleIcon from "../assets/icons/google.png";
import EyeIcon from "../assets/icons/eye.svg";
import EyeOffIcon from "../assets/icons/eyeoff.svg";

import CloseIcon from "../assets/icons/close.svg";
import LeftIcon from "../assets/icons/chevronleft.svg";

import { Error } from "./LoginForm";

interface IStartModal {
  formId: string;
  open: boolean;
  onClose?: () => void;
  onBack?: (formId: string) => void;
  onCreate: () => void;
}

const AboutYouForm = (props: IStartModal) => {
  const [email, SetEmail] = useState("");
  const [mode, SetMode] = useState("");
  const [firstname, SetFirstName] = useState("");
  const [lastname, SetLastName] = useState("");
  const [birthday, SetBirthday] = useState("");
  const [phone, SetPhone] = useState("");
  const [error, SetError] = useState("Sorry we aren’t able to create your account. You must be over 18 years old.");

  
  useEffect(() => {
    if (mode == "VerifyPhone")
    {
      SetError("The number you provided is not valid, please try again. For help, contact our help center at useland.com")
    }
  }, [mode]);

  return props?.open ? (
    <div className="modalBkg">
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: 510,
        }}
        onClick={() => {
          if (props?.onClose) props?.onClose();
        }}
      ></div>

      <div className="modalWindow" style={{ maxWidth: 550 }}>
        <div className="modalHeader d-flex justify-content-between">
          <div></div>

          <a
            href="javascript:void(0);"
            className="BarBtn"
            onClick={() => {
              if (props?.onClose) props?.onClose();
            }}
          >
            <img src={CloseIcon} />
          </a>
        </div>
        <div className="modalBody">
          {mode == "" ? <p style={{ fontSize: 32 }}>TELL US ABOUT YOU</p> : <></>}
          {mode == "VerifyPhone" ? <p style={{ fontSize: 32 }}>VERIFY YOUR PHONE NUMBER</p> : <></>}

          {mode == "" ? <p style={{ fontSize: 12 }}>
            Only your nickname will be visible to other users. We use your date
            of birth only for age verification.
          </p> : <></>}

          <div className="separator-10"></div>

          {mode == "" ? 
          <div className="d-block">
            <div className="d-flex justify-content-between">
              <div
                className="InputContainer d-flex flex-row justify-content-between align-items-center w-100"
                style={{ padding: 0, marginRight:5 }}
              >
                <input
                  type="text"
                  style={{
                    background: "transparent",
                    border: "none",
                    paddingLeft: 10,
                    paddingRight: 10,
                    color: "black",
                    width: "100%",
                  }}
                  placeholder="First name"
                  value={firstname}
                  onChange={(e: any) => {
                    SetFirstName(e.target.value);
                  }}
                />
              </div>

              <div
                className="InputContainer d-flex flex-row justify-content-between align-items-center w-100"
                style={{ padding: 0, marginLeft:5 }}
              >
                <input
                  type="text"
                  style={{
                    background: "transparent",
                    border: "none",
                    paddingLeft: 10,
                    paddingRight: 10,
                    color: "black",
                    width: "100%",
                  }}
                  placeholder="Last name"
                  value={lastname}
                  onChange={(e: any) => {
                    SetLastName(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="separator-10"></div>

            <div
              className={"InputContainer d-flex flex-row justify-content-between align-items-center " + (error ? "ErrorContainer" : "SuccessContainer")}
              style={{ padding: 0 }}
            >
              <input
                type="date"
                style={{
                  background: "transparent",
                  border: "none",
                  paddingLeft: 10,
                  paddingRight: 10,
                  color: "black",
                  width: "100%",
                }}
                placeholder="Birthday"
                value={birthday}
                onChange={(e: any) => {
                  SetBirthday(e.target.value);
                }}
              />
            </div>
          </div> : <></>}

          {mode == "VerifyPhone" ? 
          <div className=" d-flex flex-row justify-content-between align-items-center">
              <select className={"InputContainer border-0"} style={{width:100, marginRight:10}}>
                <option value=""></option>
              </select>

              <div
              className={"InputContainer w-100 d-flex flex-row justify-content-between align-items-center " + (error ? "ErrorContainer" : "SuccessContainer")}
              style={{ padding: 0 }}
            >
              <input
                type="text"
                style={{
                  background: "transparent",
                  border: "none",
                  paddingLeft: 10,
                  paddingRight: 10,
                  color: "black",
                  width: "100%",
                }}
                placeholder="Phone"
                value={phone}
                onChange={(e: any) => {
                  SetPhone(e.target.value);
                }}
              />
            </div>
          </div> : <></>}

          <Error error={error} open={error != ""}></Error>
          
          <div className="separator-10"></div>
          {mode == "VerifyPhone" ? 
          <small className="w-100 d-block" style={{textAlign:"left"}}>
            For help, please see the{" "}
            <a
              href="javascript:void(0)"
              style={{ color: "#B407F4" }}
              onClick={() => {}}
            >
              phone verification guide
            </a>
          </small> : <></>}

          <div className="separator-10"></div>
          <div className="separator-10"></div>

          <button
            className="btn btn-primary w-100 me-1 d-flex align-items-center justify-content-center"
            style={{ height: 44 }}
            
            onClick={() => {
              if (mode == "") SetMode("VerifyPhone")
              if (mode == "VerifyPhone") 
              {
                    alert("send code")
              }
            }}
          >
            {mode == "" ? <span>CONTINUE</span> : <></>}
            {mode == "VerifyPhone" ? <span>SEND CODE</span> : <></>}
          </button>

          <div className="separator-10"></div>

        {mode == "" ? 
          <small>
            By clicking “Continue”, you agree to our{" "}
            <a
              href="javascript:void(0)"
              style={{ color: "#B407F4" }}
              onClick={() => {}}
            >
              Terms of use
            </a>{" "}
            and acknowledge aur{" "}
            <a
              href="javascript:void(0)"
              style={{ color: "#B407F4" }}
              onClick={() => {}}
            >
              Privacy policy
            </a>
          </small> : <></>}

          <div className="separator-50"></div>

          <div
            className="w-100 text-lightmode"
            style={{ fontSize: 13, marginLeft: 10 }}
          >
            <a
              href=""
              style={{ marginRight: 10 }}
              className="text-decoration-none text-lightmode"
            >
              Terms of use
            </a>{" "}
            |{" "}
            <a
              href=""
              style={{ marginLeft: 10 }}
              className="text-decoration-none text-lightmode"
            >
              Privacy policy
            </a>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default AboutYouForm;
