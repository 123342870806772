import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";
import { useSelector, useDispatch } from "react-redux";

import { Link as RouteLink, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
// eslint-disable-line import/no-webpack-loader-syntax
//import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
// eslint-disable-line import/no-webpack-loader-syntax
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import MainLeftBar from "./mainleftbar";
import SearchBar from "./searchbar";
import ResultBar from "./resultbar";
import QuickBar from "./quickbar";

import StartForm from "../modals/StartForm";
import LoginForm from "../modals/LoginForm";
import SignupForm from "../modals/SignupForm";
import AboutYouForm from "../modals/AboutYouForm";
import CommentsForm from "../modals/CommnetsForm";
import PurchaseForm from "../modals/PurchaseForm";

import { SearchItem } from "../models/SearchItem";

import { AppContext, Translator } from "../App";
import { ActionType } from "../redux/reduser";
import usePresets from "../hooks/usePresets";


enum MapMode {
  unset = "unset",
  view = "view",
  newland = "newland",
}

var map: any = null;
var firstCell: any = null;
var oldmapstate: MapMode = MapMode.unset;
var lastZoom = 0.0;
var timerId: any = null;
var data: any = {};
function MainLayout() {
  let { id } = useParams();

  //const profile = useSelector((state:RootState)=>state.value)
  //const dispatch = useDispatch()

  const { state, dispatch } = useContext(AppContext);
  const { translate } = useContext(Translator);

  const mapContainer = useRef(null);
  const [lng, setLng] = useState(-70.9);
  const [lat, setLat] = useState(42.35);
  const [zoom, setZoom] = useState(3);
  const [tileCost, SetTileCost] = useState(1.0);
  const [tilesCount, SetTilesCount] = useState(0);

  const [search, SetSearch] = useState("");
  const [searchopen, SetSearchOpen] = useState(false);
  const [searchresults, SetSearchResults] = useState(false);
  const [widetoolbar, SetWideToolbar] = useState(false);
  const [firstPurchase, SetFirstPurchase] = useState(true);

  const [startFormOpen, SetStartForm] = useState(false);
  const [loginFormOpen, SetLoginForm] = useState(false);
  const [signupFormOpen, SetSignUpForm] = useState(false);
  const [aboutYouFormOpen, SetAboutYou] = useState(false);
  const [commentsFormOpen, SetComments] = useState(false);
  const [newlandfullview, SetNewLandFullView] = useState(true);

  const [mapmode, SetMapMode] = useState<MapMode>(MapMode.view);
  const [graticule, SetGraticule] = useState<any>();

  const [dolgLines, SetDolgLines] = useState<any>();
  const [shirLines, SetShirLines] = useState<any>();

  const {error: presetsError, loadPresets, presets} = usePresets({
    onCompleted: (command: string, data:any) => {
      if (command == "presets") {
         dispatch({ type: ActionType.PRESETS, payload: data });
      }
    },
  });

  const ExpandSearchClick = () => {
    SetSearchOpen(!searchopen);
  };

  const CloseSearchResultsClick = () => {
    SetSearchResults(false);
  };

  const SearchTextChange = (text: string) => {
    SetSearch(text);
  };

  const ExpandPanel = (expanded: boolean) => {
    SetWideToolbar(expanded);
  };

  const RandomLandShow = () => {
    setLat((Math.random() - 0.5) * 360);
    setLng((Math.random() - 0.5) * 100);
  };

  const QuickExpandClick = (mode: string) => {
    SetSearchOpen(!searchopen);
  };

  const OnBackClick = (formId: String) => {
    SetLoginForm(false);
    SetSignUpForm(false);

    if (formId == "1" || formId == "2") {
      SetStartForm(true);
    }
  };

  const MainButtonClick = (btn: string) => {
    if (btn == "login") {
      SetStartForm(true);
    }

    if (btn == "profile") {
      alert("Тут должен открывать профиль пользователя...");
    }
  };

  const SearchItemClick = (item: SearchItem) => {
    SetComments(true);
    SetSearchOpen(false);
  };

  const AddNewLand = () => {
    if (!state.profile) {
      // не авторизован, предлагаю автоиризоваться или зарегистрироваться
      SetStartForm(true);
    } else {
      SetMapMode(mapmode != MapMode.newland ? MapMode.newland : MapMode.view);
    }
  };

  const CloseNewLand = () => {
    SetTilesCount(0);
    SetMapMode(MapMode.view);
  };

  const FitRegion = () => {
    //var airportSW = [42.380261, -70.986013];
    //var airportNE = [42.344492, -71.033392];
    const airportSW = new mapboxgl.LngLat(42.380261, -70.986013);
    const airportNE = new mapboxgl.LngLat(42.344492, -71.033392);
    var bounds = new mapboxgl.LngLatBounds(airportSW, airportNE);
    map.fitBounds(bounds);
  };

  const GetPolygonFromPoint = (point: mapboxgl.LngLat): any => {
    var lng = point.lng;
    var lat = point.lat;
    var minLng = 0,
      maxLng = 0;
    var minLat = 0,
      maxLat = 0;

    for (var i = 0; i < dolgLines.length - 1; i++) {
      if (dolgLines[i] <= lng && dolgLines[i + 1] > lng) {
        minLng = dolgLines[i];
        maxLng = dolgLines[i + 1];
        break;
      }
    }

    for (var i = 0; i < shirLines.length - 1; i++) {
      if (shirLines[i] <= lat && shirLines[i + 1] > lat) {
        minLat = shirLines[i];
        maxLat = shirLines[i + 1];
        break;
      }
    }

    var newpoly = {
      polyId: `${minLng}_${minLat}_${maxLng}_${maxLat}`,
      p1: [minLng, minLat],
      p2: [maxLng, maxLat],
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: [
          [minLng, minLat],
          [maxLng, minLat],
          [maxLng, maxLat],
          [minLng, maxLat],
        ],
      },
    };

    return newpoly;
  };

  const ClickOnMap = useCallback(
    (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      var newpoly = GetPolygonFromPoint(e.lngLat);
      var data = map.getSource("landchanks")._options.data;
      var datatemp = map.getSource("landtempchanks")._options.data;

      if (firstCell == null) {
        firstCell = newpoly;
        datatemp.features = [firstCell];
        map.getSource("landtempchanks").setData(datatemp);
      } else {
        firstCell = null;
      }

      if (data && datatemp) {
        //var idx = data.features.findIndex((t:any)=>t.polyId == newpoly.polyId)
        /*
        if (idx<0)
        {
          data.features.push(newpoly)
        } else 
        {
          data.features = data.features.filter((t:any, i:number)=>i!=idx)
        }
*/
        //console.log(datatemp.features);
        data.features = [...datatemp.features];
        map.getSource("landchanks").setData(data);
        SetTilesCount(data.features.length);

        datatemp.features = [];
        map.getSource("landtempchanks").setData(datatemp);
      }
    },
    [dolgLines, shirLines]
  );

  const MouseMoveOnMap = useCallback(
    (e: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
      if (!firstCell) return;

      var lng = e.lngLat.lng;
      var lat = e.lngLat.lat;
      var minLng = 0,
        maxLng = 0;
      var minLat = 0,
        maxLat = 0;

      var leftTopPoint = firstCell.p1;
      var rightBottomPoint = firstCell.p2;

      clearTimeout(timerId);

      timerId = setTimeout(() => {
        var data = map.getSource("landtempchanks")._options.data;

        if (data) {
          var tempchanks: any = [];

          var dd = 0.0089831565814099;
          var shd = 0.0089831565814099;
          var minX = Math.min(leftTopPoint[0], lng);
          var maxX = Math.max(rightBottomPoint[0], lng);
          var minY = Math.min(leftTopPoint[1], lat);
          var maxY = Math.max(rightBottomPoint[1], lat);

          for (var x = minX; x < maxX; x += dd) {
            for (var y = minY; y < maxY; y += shd) {
              var newpoly = GetPolygonFromPoint(new mapboxgl.LngLat(x, y));
              tempchanks.push(newpoly);
            }
          }

          data.features = tempchanks;
        }

        map.getSource("landtempchanks").setData(data);
        SetTilesCount(data.features.length);
      }, 200);
    },
    [dolgLines, shirLines]
  );

  const CreateLines = () => {
    var graticuleCollection = {
      type: "FeatureCollection",
      features: [{}],
    };

    // долгота
    var dd = 0.0089831565814099;
    var dLines = [];
    for (let lng = -180; lng <= 180; lng += dd) {
      dLines.push(lng);
      graticuleCollection.features.push({
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [lng, -90],
            [lng, 90],
          ],
        },
        properties: { value: lng },
      });
    }

    SetDolgLines(dLines);

    // широта
    var shd = 0.0089831565814099;
    var sLines = [];
    //for (let lat = -80; lat <= 80; lat += shd) {
    for (let lat = -90; lat <= 90; lat += shd) {
      sLines.push(lat);
      graticuleCollection.features.push({
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: [
            [-180, lat],
            [180, lat],
          ],
        },
        properties: { value: lat },
      });
    }

    SetShirLines(sLines);

    SetGraticule(graticuleCollection);
  };

  const ToggleNewLandSize = (expand: boolean) => {
    SetNewLandFullView(expand);
  };

  useEffect(() => {
    SetSearchResults(search != "");
  }, [search]);

  useEffect(() => {
    if (id) {
      SetAboutYou(true);
    }
  }, [id]);

  useEffect(() => {

    loadPresets({variables:{
      names: ['comission1', 'comission6', 'comission12']
    }})

    mapboxgl.accessToken =
      "pk.eyJ1IjoiZWdvcmJ1bm92YSIsImEiOiJjbGx6ZzJ0ZzQyamNvM21vMjZra2ZsaWNhIn0.Fo0p257uhS2fx44sslC0NA";

    if (map) return; // initialize map only once

    map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/egorbunova/clm5z01nf00x901pb6c4kglom",
      center: [lng, lat],
      zoom: zoom,
    });

    map.setProjection("equirectangular");
    map.setProjection("globe");

    lastZoom = zoom;

    CreateLines();
  }, []);

  useEffect(() => {
    map.flyTo({
      center: [lat, lng],
      essential: true, // this animation is considered essential with respect to prefers-reduced-motion
    });
  }, [lng, lat, zoom]);

  useEffect(() => {
    if (oldmapstate == mapmode) return;

    const camera = map.getFreeCameraOptions();
    const cameraPosition = camera._position.toLngLat();
    var id = "graticule";

    if (mapmode === MapMode.newland) {
      lastZoom = map.getZoom();

      map.scrollZoom.disable();

      map.on("click", ClickOnMap);
      map.on("mousemove", MouseMoveOnMap);

      map.addSource(id, {
        type: "geojson",
        data: graticule
      });

      data = {
        type: "FeatureCollection",
        features: [],
      };

      map.addSource("landchanks", {
        type: "geojson",
        data: data,
      });

      map.addSource("landtempchanks", {
        type: "geojson",
        data: data,
      });

      map.addLayer({
        id: id,
        type: "line",
        source: "graticule",
        paint: {
          "line-color": "rgba(255,255,255,0.5)",
          "line-width": 1,
        },
      });

      map.addLayer({
        id: "myland",
        type: "fill",
        source: "landchanks", // reference the data source
        layout: {},
        paint: {
          "fill-color": "#0080ff", // blue color fill
          "fill-opacity": 0.5,
        },
      });

      map.addLayer({
        id: "mylandtemp",
        type: "fill",
        source: "landtempchanks", // reference the data source
        layout: {},
        paint: {
          "fill-color": "#000000", // blue color fill
          "fill-opacity": 0.3,
        },
      });

      //map.zoomIn(8, {animate: true})

      map.setProjection("equirectangular");

      
      map.flyTo({
        zoom: 11.8,
        essential: true,
      });


    } else if (mapmode == MapMode.view) {
      map.off("click", ClickOnMap);
      map.off("mousemove", MouseMoveOnMap);

      map.setProjection("globe");
      map.scrollZoom.enable();

      map.flyTo({
        zoom: lastZoom,
        essential: true,
      });

      if (map.getLayer(id)) {
        map.removeLayer(id);
      }

      if (map.getLayer("myland")) {
        map.removeLayer("myland");
      }

      if (map.getLayer("mylandtemp")) {
        map.removeLayer("mylandtemp");
      }

      if (map.getSource(id)) {
        map.removeSource(id);
      }

      if (map.getSource("landchanks")) {
        map.removeSource("landchanks");
      }

      if (map.getSource("landtempchanks")) {
        map.removeSource("landtempchanks");
      }
    }
    oldmapstate = mapmode;
  }, [mapmode]);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "block",
        position: "relative",
      }}
    >
      <div
        id="map"
        style={{
          width: "100vw",
          height: "100vh",
          zIndex: 100,
          position: "absolute",
        }}
      ></div>

      <MainLeftBar
        onExpandSearch={() => {}}
        onExpandPanel={ExpandPanel}
        onButtonClick={MainButtonClick}
        onAddNewLand={() => {}}
      ></MainLeftBar>

      <QuickBar
        open={!searchopen}
        onExpandSearch={QuickExpandClick}
        onRandomSearch={RandomLandShow}
        displaysment={widetoolbar ? 260 : 70}
      ></QuickBar>
      <SearchBar
        open={searchopen}
        onClose={ExpandSearchClick}
        onSearchText={SearchTextChange}
        displaysment={widetoolbar ? 260 : 70}
        onRandomClick={RandomLandShow}
      ></SearchBar>
      <ResultBar
        open={searchopen}
        onSearchItemClick={SearchItemClick}
        onClose={CloseSearchResultsClick}
        displaysment={widetoolbar ? 260 : 70}
      ></ResultBar>

      <div
        style={{
          position: "absolute",
          zIndex: 200,
          bottom: 50,
          textAlign: "center",
          width: "100%",
          display: "block",
        }}
      >
        <RouteLink to={"/order"}>
          <Button
            style={{ minWidth: 350, height: 50, fontWeight: "bold" }}
            variant="primary"
            onClick={AddNewLand}
            className="text-uppercase paybtnbackground "
          >
            {mapmode == MapMode.view
              ? translate(state.language, "Order your land")
              : translate(state.language, "Close")}
          </Button>
        </RouteLink>
      </div>

      <StartForm
        open={startFormOpen}
        onClose={() => {
          SetStartForm(false);
        }}
        onLogin={() => {
          SetStartForm(false);
          SetAboutYou(false);
          SetLoginForm(true);
        }}
        onSignup={() => {
          SetStartForm(false);
          SetAboutYou(false);
          SetSignUpForm(true);
        }}
      ></StartForm>

      <LoginForm
        open={loginFormOpen}
        formId="1"
        onClose={() => {
          SetLoginForm(false);
        }}
        onBack={OnBackClick}
        onSignUp={() => {
          SetLoginForm(false);
          SetAboutYou(false);
          SetSignUpForm(true);
        }}
      ></LoginForm>

      <SignupForm
        open={signupFormOpen}
        formId="2"
        onClose={() => {
          SetSignUpForm(false);
        }}
        onBack={OnBackClick}
        onLogin={() => {
          SetLoginForm(true);
          SetAboutYou(false);
          SetSignUpForm(false);
        }}
      ></SignupForm>

      <AboutYouForm
        open={aboutYouFormOpen}
        formId="3"
        onClose={() => {
          SetAboutYou(false);
        }}
        onBack={OnBackClick}
        onCreate={() => {
          SetAboutYou(false);
        }}
      ></AboutYouForm>

      <CommentsForm
        open={commentsFormOpen}
        onClose={() => {
          SetComments(false);
        }}
      ></CommentsForm>

      <PurchaseForm
        firstPurchase={firstPurchase}
        open={mapmode == MapMode.newland}
        tileCost={tileCost}
        tiles={tilesCount}
        onClose={() => {
          CloseNewLand();
        }}
        onCollapse={() => ToggleNewLandSize(false)}
        onExpand={() => ToggleNewLandSize(true)}
        displaysment={newlandfullview ? 0 : -500}
      ></PurchaseForm>
    </div>
  );
}

export default MainLayout;
