import React, { useRef, useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import CloseIcon from "../assets/icons/close.svg"
import TestBkg from "../assets/icons/testcard.png"

import GlobeIcon from "../assets/icons/globeicon.svg"
import UserIcon from "../assets/icons/user.svg"
import MarkerIcon from "../assets/icons/marker.svg"
import BellIcon from "../assets/icons/bell.svg"
import BookMarkIcon from "../assets/icons/bookmark.svg"
import RightIcon from "../assets/icons/chevron-right.svg"
import PaperIcon from "../assets/icons/paper.svg"
import HandIcon from "../assets/icons/hand.svg"
import DBIcon from "../assets/icons/database.svg"
import ChainIcon from "../assets/icons/chain.svg"
import CheckboxIcon from "../assets/icons/check-square.svg"
import QuestionIcon from "../assets/icons/question.svg"
import ShareIcon from "../assets/icons/share.svg"
import LikeIcon from "../assets/icons/like.svg"
import testAvatar from "../assets/icons/avatar.png"
import targetIcon from "../assets/icons/target.svg"

import {SearchItem} from "../models/SearchItem"

interface IResultsProps
{
    open: boolean
    displaysment: number
    onClose?: ()=>void
    onSearchItemClick?: (item: SearchItem)=>void
}

interface IDataCard
{
    item: SearchItem
    OnClick?: (item: SearchItem)=>void
}

const DataCard = (props: IDataCard) =>{
    return <div style={{width:"100%", height: "auto", display:"block", padding:5, position:"relative", overflow:"hidden"}} className="DataCard" onClick={()=>{
        if (props?.OnClick) props?.OnClick(props.item)
    }}>

            <div className="d-flex align-items-center justify-content-between w-100 mb-2">
                    <div className="d-flex align-items-center justify-content-start">
                        <img src={testAvatar} style={{width:32, height:32, marginRight:5}} className="rounded-circle" />
                        <span style={{color:"black", fontSize:13}}>Mark Tomposn</span>
                    </div>
                    <div className="d-flex align-items-center text-light-6" style={{fontSize:13}}>
                        <img src={LikeIcon} style={{width: 18, height:18, marginRight:5}} />
                        <span style={{ marginRight:5}}>150</span>
                        <img src={BookMarkIcon} style={{width: 18, height:18, marginRight:5}} />
                        <span style={{ marginRight:5}}>150</span>
                        <img src={ShareIcon} style={{width: 18, height:18, marginRight:5}} />
                        <span>150</span>
                    </div>
            </div>

            <div style={{width:"100%", height:"100%", position:"relative", zIndex:130}} className="d-flex flex-column align-items-center justify-content-between">
                <Carousel style={{width:"100%", height:"100%", position:"relative", zIndex:50}} variant={"dark"}>
                    <Carousel.Item>
                        <img src={TestBkg} style={{width:"100%", height:"100%"}} />    
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={TestBkg} style={{width:"100%", height:"100%"}} />    
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={TestBkg} style={{width:"100%", height:"100%"}} />    
                    </Carousel.Item>
                </Carousel>

                <div className="d-flex align-items-center justify-content-between w-100 px-2" style={{position:"absolute", bottom:7, left:0, zIndex:100}}>
                    <button type="button" style={{width:32, height: 32, border:"none"}} className={"BarBtn btn btn-menu p-0"} onClick={()=>{}}>
                        <img src={targetIcon} style={{width: 24, height:24}} />
                    </button>
                    <button type="button" style={{width:32, height: 32, border:"none"}} className={"BarBtn btn btn-menu p-0"} onClick={()=>{}}>
                        <img src={ShareIcon} style={{width: 24, height:24}} />
                    </button>
                </div>
            </div>
    </div>
}

const ResultBar = (props: IResultsProps) =>{

    const [activeItem, SetActiveItem] = useState<SearchItem | undefined>(undefined)
    const [selectedMode, SetMode] = useState("new")
    const [items, SetResults] = useState<SearchItem[]>([])

    useEffect(()=>{
        SetResults([{},{},{},{},{},{},{},{},{},{}])
    }, [])

    const AddSearchItems = () =>{
        var newitems = [...items, {},{},{},{},{}]
        SetResults(newitems)
    }

    const ClickDataCard = (searchItem: SearchItem)=>{ 
        SetActiveItem(searchItem)
        if (props.onSearchItemClick)
        {
            props.onSearchItemClick(searchItem)
        }
    }

    useEffect(()=>{

    }, [activeItem])

    return <div style={{width:370, bottom:10, position:"absolute", left:(props.open ? props.displaysment : -450), top:100, zIndex:200, textAlign:"center", padding:10}} className="controlDefBkg rounded d-flex flex-column">
                <div className="w-100">
                    <div className="d-flex justify-content-center w-100">
                        <div className="controlDefBkgLight rounded10 segments" style={{width:"255px"}}>
                            <button type="button" style={{width:85}} className={selectedMode == "new" ? "btn btn-actived border-tr-1" : "btn btn-inactived"} onClick={()=>SetMode("new")}>New</button>
                            <button type="button" style={{width:85}} className={selectedMode == "popular" ? "btn btn-actived border-tr-1" : "btn btn-inactived"} onClick={()=>SetMode("popular")}>Popular</button>
                            <button type="button" style={{width:85}} className={selectedMode == "favorites" ? "btn btn-actived border-tr-1" : "btn btn-inactived"} onClick={()=>SetMode("favorites")}>Favorites</button>
                        </div>
                    </div>

                    <button type="button" className="btn btn-menu-solid rotate180 position-absolute d-flex align-items-center justify-content-center" style={{width:40, height:40, right:-50, top:0}} onClick={()=>{ if (props.onClose) props.onClose() }}>
                        <img src={CloseIcon} />
                    </button>  

                </div>
               
               <div className="w-100 h-100 overflow-hidden">
                <div className="w-100 h-100 overflow-auto ResultsContainer" style={{marginTop:10, paddingBottom:40}}>
                        {items?.map((i:SearchItem, index:number)=>{
                            return <DataCard item={i} OnClick={ClickDataCard}></DataCard>
                        })}
                    <button type="button" style={{border:"none"}} className={"btn btn-whitetransaprent btn-actived"} onClick={()=>AddSearchItems()}>
                        Show more
                    </button>     
                </div>
               </div>
              
    </div>
}

export default ResultBar