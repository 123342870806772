import { useEffect, useState, useContext } from "react";

import CloseIcon from "../assets/icons/close.svg";
import LeftIcon from "../assets/icons/chevronleft.svg";
import EyeIcon from "../assets/icons/eye.svg";
import EyeOffIcon from "../assets/icons/eyeoff.svg";
import AppleIcon from "../assets/icons/apple.png";
import MicrsoftIcon from "../assets/icons/microsoft.png";
import GoogleIcon from "../assets/icons/google.png";
import { InfoCircle } from "react-bootstrap-icons";

import UserProfile from "../models/UserProfile"

import {AppContext, Translator} from "../App"
import useProfile from "../hooks/useProfile";
import { emailValidator } from "../helpers/emailValidator";
import { ActionType } from "../redux/reduser";
import { Spinner } from "react-bootstrap";

interface IStartModal {
  formId: string;
  open: boolean;
  onClose?: () => void;
  onBack?: (formId: string) => void;
  onSignUp?: () => void;
}

const LoginForm = (props: IStartModal) => {

  const {state : {language}, dispatch} = useContext(AppContext);
  const {translate} = useContext(Translator);

  const [email, SetEmail] = useState({error:"", value:""});
  const [mode, SetMode] = useState("");
  const [error, SetError] = useState("Wrong email or password");
  const [success, SetSuccess] = useState("");
  const [pssword, SetPassword] = useState({error:"", value:""});
  const [passview, SetPasswordView] = useState(false);
  const [uploading, SetUploading] = useState(false);

  const {error : profileError, TryLogin, TryRegister, TryChangePassword, loadMyProfile, profile} = useProfile({ onCompleted: (command: string, data:any) => {
    
    if (command == "login")
    {  
        let tocken = data.tocken
        let user = data.user
        localStorage.setItem("tocken", tocken)
        localStorage.setItem("user", JSON.stringify(user))
        
        dispatch({type :ActionType.LOGIN, payload: user})

        if (props?.onClose) props?.onClose();
    }

    if (command == "changePassword")
    {  
      dispatch({type :ActionType.ADDNOTIFY, payload: "Password changed..."})
      SetUploading(false);
    }
  }, onError: (error:any) => {
    SetUploading(false);
  }});

  const BackClick = () => {
    if (mode == "") {
      if (props?.onBack) props?.onBack(props.formId);
    } else {
      SetMode("");
    }
  };

  useEffect(()=>{
    let emailerror = emailValidator(email.value)
    SetEmail({...email, error: translate(language, emailerror) ?? "" })
  }, [email.value])

  useEffect(()=>{
    if (mode == "password")
    {
      if (pssword.value.length>=8)
      {
        SetPassword({...pssword, error: ""})
      } else 
      {
        SetPassword({...pssword, error: translate(language, "Your password must origin\nAt least 8 characters")})
      }
   }
}, [pssword.value, mode])

  return props?.open ? (
  <div className="modalBkg">
  <div
    style={{
      width: "100%",
      height: "100%",
      position: "absolute",
      zIndex: 510,
    }}
    onClick={() => {
      if (props?.onClose) props?.onClose();
    }}
  ></div>

  <div className="modalWindow">
    <div className="modalHeader d-flex justify-content-between">
      <a
        href="javascript:void(0);"
        className="BarBtn"
        style={{width:30}}
        onClick={() => {
          BackClick();
        }}
      >
        <img src={LeftIcon} />
      </a>

      <a
        href="javascript:void(0);"
        className="BarBtn"
        style={{width:30}}
        onClick={() => {
          if (props?.onClose) props?.onClose();
        }}
      >
        <img src={CloseIcon} />
      </a>
    </div>
    <div className="modalBody">
      {mode == "" ? (
        <p style={{ fontSize: 32, fontWeight: "bold" }} className="text-uppercase">{translate(language, "welcome back")}</p>
      ) : (
        <></>
      )}
      {mode == "password" ? (
        <p
          style={{
            fontSize: 32,
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          {translate(language, "Enter your Password")}
        </p>
      ) : (
        <></>
      )}
      {mode == "forgotpassword" ? (
        <p
          style={{
            fontSize: 32,
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          {translate(language, "Reset your password")}
        </p>
      ) : (
        <></>
      )}
      {mode == "forgotpassword" ? (
        <p style={{ fontSize: 12 }}>
          {translate(language, "Enter your address and we will send you instructions to reset your password")}
        </p>
      ) : (
        <></>
      )}

      {mode == "checkemail" ? (
        <p
          style={{
            fontSize: 32,
            fontWeight: "bold",
            textTransform: "uppercase",
          }}
        >
          {translate(language, "check your email")}
        </p>
      ) : (
        <></>
      )}
      {mode == "checkemail" ? (
        <p style={{ fontSize: 12 }}>
          {translate(language, "Please check the email address")}
          {" "}example@example.com{" "}
          {translate(language, "for instructions to reset your password")}.
        </p>
      ) : (
        <></>
      )}

      
      <Error error={profileError} open={profileError != ""}></Error>
      {profileError != "" ? <div className="separator-10"></div> : null}

      <div
        className={"InputContainer d-flex flex-row justify-content-between align-items-center " + (email.error != "" ? "ErrorContainer" : "SuccessContainer")}
        style={{ padding: 0 }}
      >
        <input
          type="text"
          style={{
            background: "transparent",
            border: "none",
            paddingLeft: 10,
            paddingRight: 10,
            color: "black",
            width: "100%",
          }}
          placeholder={translate(language, "Email address")}
          value={email.value}
          onChange={(e: any) => {
            SetEmail({...email, value: e.target.value});
          }}
        />
      </div>

      <div className="separator-10"></div>

      {mode == "password" ? (
        <>
          <div
            className={"InputContainer d-flex flex-row justify-content-between align-items-center " + (pssword.error != "" ? "ErrorContainer" : "SuccessContainer")}
            style={{ padding: 0 }}
          >
            <input
              type={passview ? "text" : "password"}
              style={{
                background: "transparent",
                border: "none",
                paddingLeft: 10,
                paddingRight: 10,
                color: "black",
                width: "100%",
              }}
              placeholder={translate(language, "Password")}
              value={pssword.value}
              onChange={(e: any) => {
                SetPassword({...pssword, value: e.target.value});
              }}
            />

            <img src={passview ? EyeOffIcon : EyeIcon} style={{width:20, height:20, marginRight:10, cursor:"pointer"}} onClick={()=>SetPasswordView(!passview)} />
          </div>
          <Error error={pssword.error} open={pssword.error != ""}></Error>
          <small className="w-100 d-flex justify-content-start mt-2">
            <a
              href="javascript:void(0)"
              onClick={() => {
                SetMode("forgotpassword");
              }}
              style={{ color: "#B407F4", textAlign: "left", fontSize: 12 }}
              className="text-decoration-none"
            >
             {translate(language, "Forgot password")}{"?"}
            </a>
          </small>
          <div className="separator-10"></div>
          <div className="separator-10"></div>{" "}
        </>
      ) : (
        <></>
      )}

      {mode == "checkemail" ? (
        <a
          href={"javascript:void(0);"}
          className="btn btn-primary w-100 me-1 d-flex align-items-center justify-content-center text-uppercase"
          style={{ height: 44 }}
          onClick={() => {
            SetUploading(true);
            TryChangePassword({variables: {email: email.value}});
          }}
        >
          {uploading && <Spinner animation="border" size="sm" style={{ marginRight: 10 }} />}
          {translate(language, "resend email")}
        </a>
      ) : (
        <></>
      )}

      

      {mode !== "checkemail" ? (
        <a
          href={"javascript:void(0);"}
          className="btn btn-primary w-100 me-1 d-flex align-items-center justify-content-center text-uppercase"
          style={{ height: 44 }}
          onClick={() => {
            if (mode == "") SetMode("password");
            if (mode == "password") {
              // тут логин
              //alert("Login");
              TryLogin({variables: {useroremail: email.value, password: pssword.value}});
            }

            if (mode == "forgotpassword") {
              SetUploading(true);
              TryChangePassword({variables: {email: email.value}});
              SetMode("checkemail");
            }
          }}
        >
          {uploading && <Spinner animation="border" size="sm" style={{ marginRight: 10 }} />}
          {translate(language, "continue")}
        </a>
      ) : (
        <></>
      )}

      <div className="separator-10"></div>

      {mode == "" ? (
        <small>
          {translate(language, "Don’t have an account")}?{" "}
          <a
            href="javascript:void(0)"
            style={{ color: "#B407F4" }}
            onClick={() => {
              if (props?.onSignUp) props?.onSignUp();
            }}
          >
            {translate(language, "Sign Up")}
          </a>
        </small>
      ) : (
        <></>
      )}

      {mode == "password" ? (
        <small>
          {translate(language, "Don’t have an account")}?{" "}
          <a
            href="javascript:void(0)"
            style={{ color: "#B407F4" }}
            onClick={() => {
              if (props?.onSignUp) props?.onSignUp();
            }}
          >
            {translate(language, "Sign Up")}
          </a>
        </small>
      ) : (
        <></>
      )}

      {mode == "forgotpassword" ? (
        <small>
          <a
            href="javascript:void(0)"
            onClick={() => {
              BackClick();
            }}
            style={{ color: "#B407F4" }}
          >
            {translate(language, "Back")}
          </a>
        </small>
      ) : (
        <></>
      )}

      {mode == "" ? (
        <>
          <div className="separator-10"></div>

          <div style={{position:"relative", marginTop:20, marginBottom:25, height:20}} className="d-flex align-items-center justify-content-center">
            <div className="divider-1" style={{zIndex:100, width:"100%", display:"block", padding:0}}></div>
            <span style={{position:"absolute", zIndex:101, background:"white",width:40, padding:0}} className="text-uppercase">{translate(language, "or")}</span>
          </div>

          <div className="separator-10"></div>

          <div
            className="InputContainer TransContainer border-1 d-flex flex-row justify-content-between align-items-center"
            style={{ padding: 0 }}
          >
            <img
              src={GoogleIcon}
              style={{ width: 25, height: 25, marginLeft: 10 }}
            />
            <a
              href={"javascript:void(0);"}
              onClick={() => {
                SetMode("password");
              }}
              style={{
                background: "transparent",
                border: "none",
                paddingLeft: 10,
                paddingRight: 10,
                color: "black",
                width: "100%",
                textAlign: "left",
              }}
              className="text-decoration-none text-lightmode"
            >
              {translate(language, "Continue with Google")}
            </a>
          </div>

          <div className="separator-10"></div>

          <div
            className="InputContainer TransContainer border-1 d-flex flex-row justify-content-between align-items-center"
            style={{ padding: 0 }}
          >
            <img
              src={MicrsoftIcon}
              style={{ width: 25, height: 25, marginLeft: 10 }}
            />
            <a
              href={"javascript:void(0);"}
              onClick={() => {
                SetMode("password");
              }}
              style={{
                background: "transparent",
                border: "none",
                paddingLeft: 10,
                paddingRight: 10,
                color: "black",
                width: "100%",
                textAlign: "left",
              }}
              className="text-decoration-none text-lightmode"
            >
              {translate(language, "Continue with Microsoft Account")}
            </a>
          </div>

          <div className="separator-10"></div>

          <div
            className="InputContainer TransContainer border-1 d-flex flex-row justify-content-between align-items-center"
            style={{ padding: 0 }}
          >
            <img
              src={AppleIcon}
              style={{ width: 25, height: 25, marginLeft: 10 }}
            />
            <a
              href={"javascript:void(0);"}
              onClick={() => {
                SetMode("password");
              }}
              style={{
                background: "transparent",
                border: "none",
                paddingLeft: 10,
                paddingRight: 10,
                color: "black",
                width: "100%",
                textAlign: "left",
              }}
              className="text-decoration-none text-lightmode"
            >
              {translate(language, "Continue with Apple")}
            </a>
          </div>
        </>
      ) : (
        <></>
      )}

      <div className="separator-50"></div>

      <div
        className="w-100 text-lightmode"
        style={{ fontSize: 13, marginLeft: 10 }}
      >
        <a
          href=""
          style={{ marginRight: 10 }}
          className="text-decoration-none text-lightmode"
        >
          {translate(language, "Terms of use")}
        </a>{" "}
        |{" "}
        <a
          href=""
          style={{ marginLeft: 10 }}
          className="text-decoration-none text-lightmode"
        >
          {translate(language, "Privacy policy")}
        </a>
      </div>
    </div>
  </div>
</div>)
   : (<></>)
};

const Error = ({ error, open }: { error: string; open: boolean }) => {
  return open ? (
    <div className="w-100 d-flex justify-content-start mt-2 error align-items-top">
      <InfoCircle style={{ marginRight: 10, marginTop:5 }} />
      <div>
      {error.split("\n").map((row)=>{
        return <div style={{textAlign:"left"}}>{row}</div>
      })}
      </div>
    </div>
  ) : (
    <></>
  );
};

export {Error}

export default LoginForm;


