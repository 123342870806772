import {
  from, HttpLink, useLazyQuery,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
  useQuery, useMutation, useSubscription, gql,
  ApolloClient,
  ApolloProvider,
  split
} from "@apollo/client";

import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { createUploadLink } from "apollo-upload-client";


const apiUrl = "https://api.uselands.com/"
const wsapiUrl = "wss://subscribtions.uselands.com"

const httpLink = createHttpLink({
  uri: apiUrl
});

const authLink = setContext((_, { headers }: any) => {
  // get the authentication token from local storage if it exists
  
  const token = localStorage.getItem("token");

  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    }
  }
});

const errorLink = onError(({ graphQLErrors, networkError }:{graphQLErrors:any, networkError:any}) => {
  
});

const httpAuthLink = authLink.concat(httpLink).concat(errorLink)

const wsLink = new GraphQLWsLink(createClient({
  url: wsapiUrl,
  connectionParams: {
    authToken: localStorage.getItem("token"),
    reconnect: true
  },
  
}));

var uploadLink = createUploadLink({uri: apiUrl})

const uploadersplitLink = split(
  ({ query }:{query:any}) => {
    const definition:any = getMainDefinition(query);
    console.log(definition)
    return (
      definition.kind === 'OperationDefinition' && definition.name && definition.name.value === 'MultipleUpload'
    );
  },
  uploadLink,
  httpAuthLink,
); 

const splitLink = split(
  ({ query } : { query: any }) => {
    const definition:any = getMainDefinition(query);
    console.log(definition)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  uploadersplitLink,
);

const apiClient = new ApolloClient({
  link: httpAuthLink,//from([splitLink, uploadLink]),
  cache: new InMemoryCache()
});  

export default apiClient