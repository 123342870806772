const en : { [name: string]: string } = {
    "uselands" : "USELANDS",
    "LOG IN/SIGN UP" : "LOG IN/SIGN UP",
    "log in" : "log in",
    "sign up" : "sign up",
    "profile" : "PROFILE",
    "add land" : "add land",
    "bell": "bell",
    "My lands" : "My lands",
    "Notifications" : "Notifications",
    "Favorites" : "Favorites",
    "English" : "English",
    "Espanol" : "Espanol",
    "About project" : "About project",
    "How to use" : "How to use",
    "Tariffs" : "Tariffs",
    "Referal link" : "Referal link",
    "Verification" : "Verification",
    "Help center" : "Help center",
    "Order your land": "Order your land",
    "Close" : "Close",
    "get started" : "get started",
    "Registration opens up the opportunity to use all the functionality of the service" : "Registration opens up the opportunity to use all the functionality of the service",
    "welcome back": "welcome back",
    "Enter your Password" : "Enter your Password",
    "Reset your password" : "Reset your password",
    "Enter your address and we will send you instructions to reset your password" : "Enter your address and we will send you instructions to reset your password",
    "check your email" : "check your email",
    "Please check the email address" : "Please check the email address",
    "for instructions to reset your password" : "for instructions to reset your password",
    "Email address" : "Email address",
    "Password" : "Password",
    "Forgot password" : "Forgot password",
    "resend email" : "resend email",
    "continue" : "continue",
    "Don’t have an account" : "Don’t have an account",
    "Sign Up": "Sign Up",
    "Back" : "Back",
    "or" : "or",
    "Continue with Google" : "Continue with Google",
    "Continue with Microsoft Account" : "Continue with Microsoft Account",
    "Continue with Apple" : "Continue with Apple",
    "Terms of use" : "Terms of use",
    "Privacy policy" : "Privacy policy",
    "create your account" : "create your account",
    "verify your email" : "verify your email",
    "Note that phone verification may be required for sign up. Your number will only be used to verify your identity for security proposes" : "Note that phone verification may be required for sign up. Your number will only be used to verify your identity for security proposes",
    "We sent an email to" : "We sent an email to",
    "Click the link inside to get started" : "Click the link inside to get started",
    "Open Gmail" : "Open Gmail",
    "Already have an account" : "Already have an account",
    "Choose a pieces of land": "Choose a pieces of land",
    "Zoom in on the planet and select the number of tiles you need on the piece of land you like": "Zoom in on the planet and select the number of tiles you need on the piece of land you like",
    "Start my free trial": "Start my free trial",
    "Click on the land tile to select": "Click on the land tile to select",
    "Click on the tile again to remove it": "Click on the tile again to remove it",
    "Today - Free trail for 30 days. Cancel anytime": "Today - Free trail for 30 days. Cancel anytime",
    "We'll send you reminder 7 days before free trail ends": "We'll send you reminder 7 days before free trail ends",
    "Your subscription starts, unless you've canceled during the trail": "Your subscription starts, unless you've canceled during the trail",
    "Try USELANDS for free": "Try USELANDS for free",
    "Welcome to Uselands": "Welcome to Uselands",
    "Next": "Next",
    "Not Right now" : "Not Right now",
    "Go to editing a plot of land": "Go to editing a plot of land",
    "Yearly": "Yearly",
    "Every Six Months": "Every Six Months",
    "Monthly": "Monthly",
    "Free 30 day trail, cancel any time": "Free 30 day trail, cancel any time",
    "Credit / Debit Card" : "Credit / Debit Card",
    "Pay with bonuses from my account": "Pay with bonuses from my account",
    "Contact information": "Contact information",
    "Card Number": "Card Number",
    "Expiration Date": "Expiration Date",
    "Country": "Country",
    "Zip Code": "Zip Code",
    "Cardholder’s Name": "Cardholder’s Name",
    "Procesing" : "Procesing",
    "Cost per one tile of land": "Cost per one tile of land",
    "Payment method" : "Payment method",
    "Subscription period" : "Subscription period",
    "Buy land" : "Buy land",
    "Congratulations!" : "Congratulations!",
    "required field": "required field",
    "Enter the correct email address": "Enter the correct email address",
    "Your password must origin\nAt least 8 characters": "Your password must origin\nAt least 8 characters",
    "quit": "QUIT",
}

export default en