import {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
  useReducer,
} from "react";

import Moment from 'react-moment';

import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";

import AppleIcon from "../assets/icons/apple.png";
import MicrsoftIcon from "../assets/icons/microsoft.png";
import GoogleIcon from "../assets/icons/google.png";
import EyeIcon from "../assets/icons/eye.svg";
import EyeOffIcon from "../assets/icons/eyeoff.svg";
import CheckIcon from "../assets/icons/check.svg";

import CloseIcon from "../assets/icons/close.svg";
import LeftIcon from "../assets/icons/chevronleft.svg";
import RightIcon from "../assets/icons/chevron-right.svg";

import { Error } from "./LoginForm";

import { AppContext, Translator } from "../App";
import useLands from "../hooks/useAreas";
import { profile } from "console";

interface IPurchaseModal {
  open: boolean;
  firstPurchase?: boolean;
  tiles: number;
  tileCost: number;
  title?: string;
  cost1?: number;
  cost6?: number;
  cost12?: number; 
  totalCost?: number;
  paydatDate30?: Date;
  onClose?: () => void;
  onCollapse?: () => void;
  onExpand?: () => void;
  onNextScreen?: () => void;
  onFirstClick?: () => void;
  onSecondClick?: () => void;
  selected?: "month" | "halfyear" | "year";
  displaysment: number;
  SetCheckedElement?: (paymentType: "month" | "halfyear" | "year", costOfPeriod: number) => void;

  email?: string;
  OnChangeEmail?: (newemail: string) => void;

  card?: any;
  onChangeCard?: (property: string, value: string) => void;
}

const Step1 = (props: IPurchaseModal) => {
  const {
    state: { language },
    dispatch,
  } = useContext(AppContext);
  const { translate } = useContext(Translator);

  return (
    <div
      className="dialog"
      style={{
        maxWidth: 500,
        width: 500,
        display: "block",
        position: "absolute",
        zIndex: 100,
        margin: 5,
        right: props.displaysment,
        padding: "30px 30px",
        height: "99vh",
        background: "white",
      }}
    >
      <div className="modalHeader d-flex justify-content-between">
        <div style={{ position: "absolute", left: -50, top: 0 }}>
          <button
            type="button"
            className="btn btn-menu-solid rotate180 d-flex align-items-center justify-content-center"
            style={{ width: 40, height: 40, marginLeft: 0, top: 0 }}
            onClick={() => {
              if (props.onClose) props.onClose();
            }}
          >
            <img src={CloseIcon} />
          </button>
          <span style={{ height: 20, display: "block" }}></span>
          <button
            type="button"
            className="btn btn-menu-solid d-flex align-items-center justify-content-center"
            style={{ width: 40, height: 40, marginLeft: 0, top: 0 }}
            onClick={() => {
              if (props.displaysment == 0 && props.onCollapse)
                props.onCollapse();
              else if (props.displaysment < 0 && props.onExpand)
                props.onExpand();
            }}
          >
            <img
              src={RightIcon}
              style={{
                transform:
                  props.displaysment < 0 ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </button>
        </div>

        <div className="text-left text-uppercase ">
          <h3 style={{ fontWeight: 500 }}>
            {translate(language, props?.title ?? "")}
          </h3>
        </div>
      </div>
      <div className="modalBody">
        <div className=" my-2" style={{ height: 1 }}></div>
        <p style={{ textAlign: "left" }}>
          {translate(
            language,
            "Zoom in on the planet and select the number of tiles you need on the piece of land you like"
          )}
          {"."}
        </p>

        <ul style={{ textAlign: "left" }} className="text-muted">
          <li>{translate(language, "Click on the land tile to select")}</li>
          <li>{translate(language, "Click on the tile again to remove it")}</li>
        </ul>

        <div className="separator my-2" style={{ height: 1 }}></div>

        <div className="d-flex align-items-center justify-content-between py-3">
          <h4>Number of land tiles:</h4>
          <h4>{props.tiles}</h4>
        </div>

        <div className="separator my-2" style={{ height: 1 }}></div>

        <div className="d-flex align-items-center justify-content-between py-3">
          <h4>Total rental cost per month:</h4>
          <h4>${(props.tiles * props.tileCost * (props?.cost1 ?? 1)).toFixed(2)}</h4>
        </div>

        <div className="d-flex align-items-center justify-content-between py-3">
          <h5 className="text-muted">
            {" "}
            {translate(language, "Cost per one tile of land")}
            {":"}
          </h5>
          <h5 className="text-muted">${(props.tileCost * (props?.cost1 ?? 1)).toFixed(2)}</h5>
        </div>

        {props?.firstPurchase == true ? (
          <>
            <div className="separator my-2" style={{ height: 1 }}></div>
            <p style={{ textAlign: "left", fontWeight: "bold" }}>
              {translate(
                language,
                "Today - Free trail for 30 days. Cancel anytime"
              )}
              {"."}
            </p>

            <ul style={{ textAlign: "left" }} className="text-muted">
              <li>
                {translate(
                  language,
                  "We'll send you reminder 7 days before free trail ends"
                )}
              </li>
              <li>
                {translate(
                  language,
                  "Your subscription starts, unless you've canceled during the trail"
                )}
              </li>
            </ul>
            <div className=" my-2" style={{ height: 1 }}></div>
          </>
        ) : (
          <div className=" my-3" style={{ height: 1 }}></div>
        )}

        <Button
          style={{ minWidth: 350, height: 50, fontWeight: "bold" }}
          variant="primary"
          onClick={() => {
            if (props?.onNextScreen) props?.onNextScreen();
          }}
          className="text-uppercase paybtnbackground"
        >
          {translate(
            language,
            props?.firstPurchase == true ? "Start my free trial" : "Next"
          )}
        </Button>
      </div>
    </div>
  );
};

const Step2 = (props: IPurchaseModal) => {
  const {
    state: { language, presets },
    dispatch,
  } = useContext(AppContext);
  const { translate } = useContext(Translator);
  
  return (
    <div
      className="dialog"
      style={{
        maxWidth: 500,
        width: 500,
        display: "block",
        position: "absolute",
        zIndex: 100,
        margin: 5,
        right: props.displaysment,
        padding: "30px 30px",
        height: "99vh",
        background: "white",
      }}
    >
      <div className="modalHeader d-flex justify-content-between">
        <div style={{ position: "absolute", left: -50, top: 0 }}>
          <button
            type="button"
            className="btn btn-menu-solid rotate180 d-flex align-items-center justify-content-center"
            style={{ width: 40, height: 40, marginLeft: 0, top: 0 }}
            onClick={() => {
              if (props.onClose) props.onClose();
            }}
          >
            <img src={CloseIcon} alt="" />
          </button>
          <span style={{ height: 20, display: "block" }}></span>
          <button
            type="button"
            className="btn btn-menu-solid d-flex align-items-center justify-content-center"
            style={{ width: 40, height: 40, marginLeft: 0, top: 0 }}
            onClick={() => {
              if (props.displaysment == 0 && props.onCollapse)
                props.onCollapse();
              else if (props.displaysment < 0 && props.onExpand)
                props.onExpand();
            }}
          >
            <img
              src={RightIcon}
              alt=""
              style={{
                transform:
                  props.displaysment < 0 ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </button>
        </div>

        <div className="text-left text-uppercase">
          <h3 style={{ fontWeight: 500 }}>
            {translate(language, props?.title ?? "")}
          </h3>
        </div>
      </div>
      <div className="modalBody">
        <div className=" my-2" style={{ height: 1 }}></div>

        {props?.firstPurchase === true ? (
          <ul
            style={{ textAlign: "left", listStyleType: "none", paddingLeft: 0 }}
            className="text-muted  checked-list "
          >
            <li>
              <img src={CheckIcon} alt="" />{" "}
              {translate(language, "Free 30 day trail, cancel any time")}
            </li>
            <li>
              <img src={CheckIcon} alt="" />{" "}
              {translate(
                language,
                "We'll send you reminder 7 days before free trail ends"
              )}
            </li>
          </ul>
        ) : (
          <>
            <p>
              A yearly subscription gives you the opportunity to get three
              months of use of a plot of land for free
            </p>
          </>
        )}

        <div className=" my-2" style={{ height: 1 }}></div>

        <ReportItem
          title={translate(language, "Yearly")}
          badge={`Best Value - Save $${((props.tileCost * props.tiles * 12 * (props?.cost1 ?? 0)) - (props.tileCost * props.tiles * 12 * (props?.cost12 ?? 0))).toFixed(2)}`}
          checked={props?.selected == "year"}
          onSelect={() => {
            if (props?.SetCheckedElement) props?.SetCheckedElement("year", (props.tileCost * props.tiles * 12 * (props?.cost12 ?? 0)));
          }}
        >
          <div className="">
            <small className="text-dark">
              ${(props.tileCost * props.tiles * 12 * (props?.cost12 ?? 0)).toFixed(2)} <span className="text-muted">(${(props.tileCost * props.tiles * (props?.cost12 ?? 0)).toFixed(2)}/month)</span>
            </small>
          </div>
        </ReportItem>

        <div className=" my-2" style={{ height: 1 }}></div>

        <ReportItem
          title={translate(language, "Every Six Months")}
          checked={props?.selected == "halfyear"}
          onSelect={() => {
            if (props?.SetCheckedElement) props?.SetCheckedElement("halfyear", (props.tileCost * props.tiles * 6 * (props?.cost6 ?? 0)));
          }}
        >
          <div className="">
            <small className="text-dark">
              ${(props.tileCost * props.tiles * 6 * (props?.cost6 ?? 0)).toFixed(2)} <span className="text-muted">(${(props.tileCost * props.tiles * (props?.cost6 ?? 0)).toFixed(2)}/month)</span>
            </small>
          </div>
        </ReportItem>

        <div className=" my-2" style={{ height: 1 }}></div>

        <ReportItem
          title={translate(language, "Monthly")}
          checked={props?.selected == "month"}
          onSelect={() => {
            if (props?.SetCheckedElement) props?.SetCheckedElement("month", (props.tileCost * props.tiles * (props?.cost1 ?? 0)));
          }}
        >
          <div className="">
            <small className="text-dark">${(props.tileCost * props.tiles * (props?.cost1 ?? 0)).toFixed(2)}</small>
          </div>
        </ReportItem>

        <div className=" my-2" style={{ height: 1 }}></div>

        {props?.firstPurchase == true ? (
          <>
            <div className="d-flex align-items-center justify-content-between text-muted">
              <span>Due  <Moment format="DD MMMM, YYYY">{props?.paydatDate30?.toISOString() ?? ""}</Moment>:</span>
              <span>${props?.totalCost?.toFixed(2) ?? 0}</span>
            </div>
            <div className=" my-1" style={{ height: 1 }}></div>
            <div className="d-flex align-items-center justify-content-between">
              <span>
                <b>Due today</b>{" "}
                <span style={{ color: "green" }}>(30 days free)</span>
              </span>
              <span>$0</span>
            </div>
          </>
        ) : (
          <>
            <div className="d-flex align-items-center justify-content-between text-muted">
              <span>Amount:</span>
              <span>$26.73</span>
            </div>
            <div className=" my-1" style={{ height: 1 }}></div>
            <div className="d-flex align-items-center justify-content-between text-muted">
              <span>Tax</span>
              <span>$0</span>
            </div>
          </>
        )}

        <div className=" my-4" style={{ height: 1 }}></div>

        <Button
          style={{ minWidth: 350, height: 50, fontWeight: "bold" }}
          variant="primary"
          onClick={() => {
            if (props?.onNextScreen) props?.onNextScreen();
          }}
          className="text-uppercase paybtnbackground"
        >
          {translate(language, "Next")}
        </Button>
      </div>
      <div className="modalFooter px-5 py-3 text-muted">
        <small>
          By clicking “Next”, you agree to our{" "}
          <a href="#" style={{ color: "purple" }}>
            Terms of use
          </a>{" "}
          and acknowledge our{" "}
          <a href="#" style={{ color: "purple" }}>
            Privacy policy
          </a>
        </small>
      </div>
    </div>
  );
};

const Step3 = (props: IPurchaseModal) => {
  const {
    state: { language },
    dispatch,
  } = useContext(AppContext);
  const { translate } = useContext(Translator);

  return (
    <div
      className="dialog"
      style={{
        maxWidth: 500,
        width: 500,
        display: "block",
        position: "absolute",
        zIndex: 100,
        margin: 5,
        right: props.displaysment,
        padding: "30px 30px",
        height: "99vh",
        background: "white",
      }}
    >
      <div className="modalHeader d-flex justify-content-between">
        <div style={{ position: "absolute", left: -50, top: 0 }}>
          <button
            type="button"
            className="btn btn-menu-solid rotate180 d-flex align-items-center justify-content-center"
            style={{ width: 40, height: 40, marginLeft: 0, top: 0 }}
            onClick={() => {
              if (props.onClose) props.onClose();
            }}
          >
            <img src={CloseIcon} alt="" />
          </button>
          <span style={{ height: 20, display: "block" }}></span>
          <button
            type="button"
            className="btn btn-menu-solid d-flex align-items-center justify-content-center"
            style={{ width: 40, height: 40, marginLeft: 0, top: 0 }}
            onClick={() => {
              if (props.displaysment == 0 && props.onCollapse)
                props.onCollapse();
              else if (props.displaysment < 0 && props.onExpand)
                props.onExpand();
            }}
          >
            <img
              alt=""
              src={RightIcon}
              style={{
                transform:
                  props.displaysment < 0 ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </button>
        </div>

        <div className="text-left text-uppercase">
          <h3 style={{ fontWeight: 500 }}>
            {translate(language, props?.title ?? "")}
          </h3>
        </div>
      </div>
      <div className="modalBody">
        <div className=" my-2" style={{ height: 1 }}></div>

        {props?.firstPurchase === true ? (
          <ul
            style={{ textAlign: "left", listStyleType: "none", paddingLeft: 0 }}
            className="text-muted checked-list"
          >
            <li>
              <img src={CheckIcon} alt="" />{" "}
              {translate(language, "Free 30 day trail, cancel any time")}
            </li>
            <li>
              <img src={CheckIcon} alt="" />{" "}
              {translate(
                language,
                "We'll send you reminder 7 days before free trail ends"
              )}
            </li>
          </ul>
        ) : (
          <></>
        )}

        <div className="my-3" style={{ height: 1 }}></div>

        <label htmlFor="emailInput" className="w-100 text-start py-1">
          {translate(language, "Contact information")}
        </label>
        <div
          className={
            "InputContainer d-flex flex-row justify-content-between align-items-center "
          }
          style={{ padding: 0 }}
        >
          <input
            id="emailInput"
            type={"email"}
            style={{
              background: "transparent",
              border: "none",
              paddingLeft: 10,
              paddingRight: 10,
              color: "black",
              width: "100%",
            }}
            placeholder={"Email"}
            value={props?.email}
            onChange={(e: any) => {
              if (props?.OnChangeEmail) props?.OnChangeEmail(e.target.value);
            }}
          />
        </div>

        <div className="my-3" style={{ height: 1 }}></div>
        <div className="separator my-2" style={{ height: 1 }}></div>
        <div className="my-3" style={{ height: 1 }}></div>

        <ReportItem
          title={translate(language, "Credit / Debit Card")}
          checked={props?.selected == "year"}
          onSelect={() => {
            if (props?.SetCheckedElement) props?.SetCheckedElement(1);
          }}
        ></ReportItem>

        {props?.selected == 1 ? (
          <>
            <div
              className="w-100"
              style={{
                maxHeight: 200,
                height: "auto",
                overflowY: "auto",
                overflowX: "clip",
              }}
            >
              <label
                htmlFor="cardNumberInput"
                className="w-100 text-start py-1"
              >
                {translate(language, "Card Number")}
                <b style={{ color: "red" }}>*</b>
              </label>
              <div
                className={
                  "InputContainerBorder d-flex flex-row justify-content-between align-items-center "
                }
                style={{ padding: 0 }}
              >
                <input
                  id="cardNumberInput"
                  type={""}
                  style={{
                    background: "transparent",
                    border: "none",
                    paddingLeft: 10,
                    paddingRight: 10,
                    color: "black",
                    width: "100%",
                  }}
                  placeholder={"Email"}
                  value={props?.card?.cardNumber}
                  onChange={(e: any) => {
                    if (props?.onChangeCard)
                      props?.onChangeCard("cardNumber", e.target.value);
                  }}
                />
              </div>

              <div className="my-1" style={{ height: 1 }}></div>

              <div className="row">
                <div className="col-6">
                  <label
                    htmlFor="cardNumberInput"
                    className="w-100 text-start py-1"
                  >
                    {translate(language, "Expiration Date")}
                    <b style={{ color: "red" }}>*</b>
                  </label>
                  <div
                    className={
                      "InputContainerBorder d-flex flex-row justify-content-between align-items-center "
                    }
                    style={{ padding: 0 }}
                  >
                    <input
                      id="cardNumberInput"
                      type={""}
                      style={{
                        background: "transparent",
                        border: "none",
                        paddingLeft: 10,
                        paddingRight: 10,
                        color: "black",
                        width: "100%",
                      }}
                      placeholder={"Email"}
                      value={props?.card?.expirationDate}
                      onChange={(e: any) => {
                        if (props?.onChangeCard)
                          props?.onChangeCard("expirationDate", e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <label
                    htmlFor="cardNumberInput"
                    className="w-100 text-start py-1"
                  >
                    {"CVC"}
                    <b style={{ color: "red" }}>*</b>
                  </label>
                  <div
                    className={
                      "InputContainerBorder d-flex flex-row justify-content-between align-items-center "
                    }
                    style={{ padding: 0 }}
                  >
                    <input
                      id="cardNumberInput"
                      type={""}
                      style={{
                        background: "transparent",
                        border: "none",
                        paddingLeft: 10,
                        paddingRight: 10,
                        color: "black",
                        width: "100%",
                      }}
                      placeholder={"Email"}
                      value={props?.card?.Cvc}
                      onChange={(e: any) => {
                        if (props?.onChangeCard)
                          props?.onChangeCard("Cvc", e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="my-1" style={{ height: 1 }}></div>

              <label
                htmlFor="cardNumberInput"
                className="w-100 text-start py-1"
              >
                {translate(language, "Country")}
                <b style={{ color: "red" }}>*</b>
              </label>
              <div
                className={
                  "InputContainerBorder d-flex flex-row justify-content-between align-items-center "
                }
                style={{ padding: 0 }}
              >
                <input
                  id="cardNumberInput"
                  type={""}
                  style={{
                    background: "transparent",
                    border: "none",
                    paddingLeft: 10,
                    paddingRight: 10,
                    color: "black",
                    width: "100%",
                  }}
                  placeholder={"Email"}
                  value={props?.card?.Country}
                  onChange={(e: any) => {
                    if (props?.onChangeCard)
                      props?.onChangeCard("Country", e.target.value);
                  }}
                />
              </div>

              <div className="my-1" style={{ height: 1 }}></div>

              <label
                htmlFor="cardNumberInput"
                className="w-100 text-start py-1"
              >
                {translate(language, "Zip Code")}
                <b style={{ color: "red" }}>*</b>
              </label>
              <div
                className={
                  "InputContainerBorder d-flex flex-row justify-content-between align-items-center "
                }
                style={{ padding: 0 }}
              >
                <input
                  id="cardNumberInput"
                  type={""}
                  style={{
                    background: "transparent",
                    border: "none",
                    paddingLeft: 10,
                    paddingRight: 10,
                    color: "black",
                    width: "100%",
                  }}
                  placeholder={"Email"}
                  value={props?.card?.ZipCode}
                  onChange={(e: any) => {
                    if (props?.onChangeCard)
                      props?.onChangeCard("ZipCode", e.target.value);
                  }}
                />
              </div>

              <div className="my-1" style={{ height: 1 }}></div>

              <label
                htmlFor="cardNumberInput"
                className="w-100 text-start py-1"
              >
                {translate(language, "Cardholder’s Name")}
                <b style={{ color: "red" }}>*</b>
              </label>
              <div
                className={
                  "InputContainerBorder d-flex flex-row justify-content-between align-items-center "
                }
                style={{ padding: 0 }}
              >
                <input
                  id="cardNumberInput"
                  type={""}
                  style={{
                    background: "transparent",
                    border: "none",
                    paddingLeft: 10,
                    paddingRight: 10,
                    color: "black",
                    width: "100%",
                  }}
                  placeholder={"Email"}
                  value={props?.card?.ownerName}
                  onChange={(e: any) => {
                    if (props?.onChangeCard)
                      props?.onChangeCard("ownerName", e.target.value);
                  }}
                />
              </div>

              <div className=" my-2" style={{ height: 1 }}></div>

              <p className="text-start text-muted">
                <small>
                  To authorize the card, a small amount be charged and
                  immediately refunded. This site is protected by reCAPTCHA and
                  the Google Privacy Policy and Terms of Service apply. By
                  continuing, I warrant that this location information is
                  accurate.
                </small>
              </p>
            </div>
          </>
        ) : (
          <></>
        )}

        <div className=" my-2" style={{ height: 1 }}></div>

        <ReportItem
          title={translate(language, "Pay with bonuses from my account")}
          checked={props?.selected == 2}
          onSelect={() => {
            if (props?.SetCheckedElement) props?.SetCheckedElement(2);
          }}
        ></ReportItem>

        {props?.selected == 2 ? (
          <>
            <div className="d-flex align-items-center justify-content-between py-3">
              <h4>Your Balance:</h4>
              <h4>$0.00</h4>
            </div>

            <p className="text-start">
              The bonuses you've earned for using the referral link are
              displayed here. You can learn more about the program by clicking
              here.
            </p>

            <p className="text-start">
              You can use your bonuses to purchase new lands
            </p>
          </>
        ) : (
          <></>
        )}

        <div className="separator my-1" style={{ height: 1 }}></div>

        <div className="my-4" style={{ height: 1 }}></div>

        {props?.firstPurchase == true ? (
          <>
            <div className="d-flex align-items-center justify-content-between text-muted">
              <span>Due November 2, 2023:</span>
              <span>$26.73</span>
            </div>
            <div className=" my-1" style={{ height: 1 }}></div>
            <div className="d-flex align-items-center justify-content-between">
              <span>
                <b>Due today</b>{" "}
                <span style={{ color: "green" }}>(30 days free)</span>
              </span>
              <span>$0</span>
            </div>
          </>
        ) : (
          <>
            <div className="d-flex align-items-center justify-content-between text-muted">
              <span>Amount:</span>
              <span>$26.73</span>
            </div>
            <div className=" my-1" style={{ height: 1 }}></div>
            <div className="d-flex align-items-center justify-content-between text-muted">
              <span>Tax</span>
              <span>$0</span>
            </div>
          </>
        )}

        <div className=" my-4" style={{ height: 1 }}></div>

        <Button
          style={{ minWidth: 350, height: 50, fontWeight: "bold" }}
          variant="primary"
          onClick={() => {
            if (props?.onNextScreen) props?.onNextScreen();
          }}
          className="text-uppercase paybtnbackground"
        >
          {translate(
            language,
            props?.firstPurchase == true ? "Next" : "Buy land"
          )}
        </Button>
      </div>
      <div className="modalFooter px-5 py-3 text-muted">
        <small>
          By clicking “Next”, you agree to our{" "}
          <a href="#" style={{ color: "purple" }}>
            Terms of use
          </a>{" "}
          and acknowledge our{" "}
          <a href="#" style={{ color: "purple" }}>
            Privacy policy
          </a>
        </small>
      </div>
    </div>
  );
};

const Step4 = (props: IPurchaseModal): React.JSX.Element => {
  const {
    state: { language },
    dispatch,
  } = useContext(AppContext);
  const { translate } = useContext(Translator);

  return (
    <div
      className="dialog"
      style={{
        maxWidth: 500,
        width: 500,
        display: "block",
        position: "absolute",
        zIndex: 100,
        margin: 5,
        right: props.displaysment,
        padding: "30px 30px",
        height: "99vh",
        background: "white",
      }}
    >
      <div className="modalHeader d-flex justify-content-between">
        <div style={{ position: "absolute", left: -50, top: 0 }}>
          <button
            type="button"
            className="btn btn-menu-solid rotate180 d-flex align-items-center justify-content-center"
            style={{ width: 40, height: 40, marginLeft: 0, top: 0 }}
            onClick={() => {
              if (props.onClose) props.onClose();
            }}
          >
            <img src={CloseIcon} alt="" />
          </button>
          <span style={{ height: 20, display: "block" }}></span>
          <button
            type="button"
            className="btn btn-menu-solid d-flex align-items-center justify-content-center"
            style={{ width: 40, height: 40, marginLeft: 0, top: 0 }}
            onClick={() => {
              if (props.displaysment == 0 && props.onCollapse)
                props.onCollapse();
              else if (props.displaysment < 0 && props.onExpand)
                props.onExpand();
            }}
          >
            <img
              alt=""
              src={RightIcon}
              style={{
                transform:
                  props.displaysment < 0 ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </button>
        </div>

        <div className="text-left text-uppercase">
          <h3 style={{ fontWeight: 500 }}>
            {translate(language, props?.title ?? "")}
          </h3>
        </div>
      </div>
      <div className="modalBody">
        <div className=" my-2" style={{ height: 1 }}></div>

        {props?.firstPurchase === true ? (
          <ul
            style={{ textAlign: "left", listStyleType: "none", paddingLeft: 0 }}
            className="text-muted  checked-list "
          >
            <li>
              <img src={CheckIcon} alt="" />{" "}
              {translate(language, "Free 30 day trail, cancel any time")}
            </li>
            <li>
              <img src={CheckIcon} alt="" />{" "}
              {translate(
                language,
                "We'll send you reminder 7 days before free trail ends"
              )}
            </li>
          </ul>
        ) : (
          <></>
        )}

        <div className="my-3" style={{ height: 1 }}></div>

        <label htmlFor="emailInput" className="w-100 text-start py-1">
          {translate(language, "Contact information")}
        </label>
        <div
          className={
            "InputContainer d-flex flex-row justify-content-between align-items-center "
          }
          style={{ padding: 0 }}
        >
          <input
            id="emailInput"
            type={"email"}
            style={{
              background: "transparent",
              border: "none",
              paddingLeft: 10,
              paddingRight: 10,
              color: "black",
              width: "100%",
            }}
            placeholder={"Email"}
            value={props?.email}
            onChange={(e: any) => {
              if (props?.OnChangeEmail) props?.OnChangeEmail(e.target.value);
            }}
          />
        </div>

        <div className="my-3" style={{ height: 1 }}></div>
        <div className="separator my-2" style={{ height: 1 }}></div>
        <div className="my-3" style={{ height: 1 }}></div>

        {props?.firstPurchase == true ? (
          <>
            <div className="d-flex align-items-center justify-content-between text-muted">
              <span>Due <Moment format="DD MMMM, YYYY">{props?.paydatDate30?.toISOString() ?? ""}</Moment>:</span>
              <span>${(props?.totalCost ?? 0).toFixed(2)}</span>
            </div>
            <div className=" my-1" style={{ height: 1 }}></div>
            <div className="d-flex align-items-center justify-content-between">
              <span>
                <b>Due today</b>{" "}
                <span style={{ color: "green" }}>(30 days free)</span>
              </span>
              <span>$0</span>
            </div>{" "}
          </>
        ) : (
          <>
            <div className="d-flex align-items-center justify-content-between text-muted">
              <span>Amount:</span>
              <span>${(props?.totalCost ?? 0).toFixed(2)}</span>
            </div>
            <div className=" my-1" style={{ height: 1 }}></div>
            <div className="d-flex align-items-center justify-content-between text-muted">
              <span>Tax</span>
              <span>$0</span>
            </div>
          </>
        )}

        <div className=" my-4" style={{ height: 1 }}></div>

        <Button
          style={{ minWidth: 350, height: 50, fontWeight: "bold" }}
          variant="secondary"
          onClick={() => {
            if (props?.onNextScreen) props?.onNextScreen();
          }}
          className="text-uppercase"
        >
          {translate(language, "Procesing")}
        </Button>
        <div className=" my-1" style={{ height: 1 }}></div>
        <p>Paying with Visa - 0000 Edit</p>
      </div>

      <div className="modalFooter px-5 py-3 text-muted">
        <small>
          By clicking “Next”, you agree to our{" "}
          <a href="#" style={{ color: "purple" }}>
            Terms of use
          </a>{" "}
          and acknowledge our{" "}
          <a href="#" style={{ color: "purple" }}>
            Privacy policy
          </a>
        </small>
      </div>
    </div>
  );
};

const Step5 = (props: IPurchaseModal) => {
  const {
    state: { language },
    dispatch,
  } = useContext(AppContext);
  const { translate } = useContext(Translator);

  return (
    <div
      className="dialog"
      style={{
        maxWidth: 500,
        width: 500,
        display: "block",
        position: "absolute",
        zIndex: 100,
        margin: 5,
        right: props.displaysment,
        padding: "30px 30px",
        height: "99vh",
        background: "white",
      }}
    >
      <div className="modalHeader d-flex justify-content-between">
        <div style={{ position: "absolute", left: -50, top: 0 }}>
          <button
            type="button"
            className="btn btn-menu-solid rotate180 d-flex align-items-center justify-content-center"
            style={{ width: 40, height: 40, marginLeft: 0, top: 0 }}
            onClick={() => {
              if (props.onClose) props.onClose();
            }}
          >
            <img src={CloseIcon} />
          </button>
          <span style={{ height: 20, display: "block" }}></span>
          <button
            type="button"
            className="btn btn-menu-solid d-flex align-items-center justify-content-center"
            style={{ width: 40, height: 40, marginLeft: 0, top: 0 }}
            onClick={() => {
              if (props.displaysment == 0 && props.onCollapse)
                props.onCollapse();
              else if (props.displaysment < 0 && props.onExpand)
                props.onExpand();
            }}
          >
            <img
              src={RightIcon}
              style={{
                transform:
                  props.displaysment < 0 ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </button>
        </div>

        <div className="text-left text-uppercase">
          <h3 style={{ fontWeight: 500 }}>
            {translate(language, props?.title ?? "")}
          </h3>
        </div>
      </div>
      <div className="modalBody">
        <div className=" my-2" style={{ height: 1 }}></div>
        <p style={{ textAlign: "left" }}>
          {
            "Welcome words describing the benefits that the user can now take advantage of."
          }
        </p>
        <p style={{ textAlign: "left" }}>
          {
            "Welcome words describing the benefits that the user can now take advantage of."
          }
        </p>
        <p style={{ textAlign: "left" }}>
          {
            "Welcome words describing the benefits that the user can now take advantage of."
          }
        </p>
        <p style={{ textAlign: "left" }}>
          {
            "Welcome words describing the benefits that the user can now take advantage of."
          }
        </p>
        <p style={{ textAlign: "left" }}>
          {
            "Welcome words describing the benefits that the user can now take advantage of."
          }
        </p>
        <p style={{ textAlign: "left" }}>
          {
            "Welcome words describing the benefits that the user can now take advantage of."
          }
        </p>
        <p style={{ textAlign: "left" }}>
          {
            "Welcome words describing the benefits that the user can now take advantage of."
          }
        </p>
        <p style={{ textAlign: "left" }}>
          {
            "Welcome words describing the benefits that the user can now take advantage of."
          }
        </p>
        <p style={{ textAlign: "left" }}>
          {
            "Welcome words describing the benefits that the user can now take advantage of."
          }
        </p>
        <p style={{ textAlign: "left" }}>
          {
            "Welcome words describing the benefits that the user can now take advantage of."
          }
        </p>

        <div className=" my-2" style={{ height: 1 }}></div>
      </div>

      <div className="modalFooter px-4 py-4 w-100">
        <Button
          style={{ height: 50, fontWeight: "thin" }}
          variant="primary"
          onClick={() => {
            if (props?.onFirstClick) props?.onFirstClick();
          }}
          className="w-100"
        >
          {translate(language, "Go to editing a plot of land")}
        </Button>
        <div className=" my-2" style={{ height: 1 }}></div>
        <Button
          style={{ height: 50, fontWeight: "thin" }}
          variant="secondary"
          onClick={() => {
            if (props?.onSecondClick) props?.onSecondClick();
          }}
          className="w-100"
        >
          {translate(language, "Not Right now")}
        </Button>
      </div>
    </div>
  );
};

const ReportItem = ({
  title,
  badge,
  children,
  checked,
  onSelect,
}: {
  title: string;
  badge?: string;
  children?: React.ReactNode;
  checked: boolean;
  onSelect: () => void;
}): JSX.Element => {
  return (
    <div
      className="d-flex align-items-top"
      onClick={() => onSelect()}
      style={{ cursor: "pointer" }}
    >
      <div
        style={{
          width: 24,
          height: 22,
          borderRadius: 11,
          display: "block",
          border: checked ? "7px solid violet" : "1px solid black",
          background: "white",
          marginRight: 10,
          marginTop: 2,
        }}
      ></div>
      <div className="d-flex flex-column align-items-start w-100">
        <div className="d-flex align-items-center justify-content-start">
          <h5 style={{ fontWeight: "Semi Bold", marginRight: 10 }}>{title}</h5>
          {badge ? (
            <Badge pill bg="danger">
              {badge}
            </Badge>
          ) : (
            <></>
          )}
        </div>
        <p style={{ textAlign: "justify" }}>{children}</p>
      </div>
    </div>
  );
};

const PurchaseForm = (props: IPurchaseModal) => {
  const {
    state: { language, presets, profile, dataStore },
    dispatch,
  } = useContext(AppContext);

  const { translate } = useContext(Translator);
    
  const [checkedPaymentTypeIndex, SetcheckedPaymentTypeIndex] = useState(1);
  const [step, SetStep] = useState(1);
  const [mode, SetMode] = useState("");
  const [error, SetError] = useState(
    "Sorry we aren’t able to create your account. You must be over 18 years old."
  );

  const [paydatDate30, setPaydatDate] = useState(new Date());
  const [totalCost, setTotalCost] = useState( 0 );

  useEffect(() => {
    var date = new Date(); // Now
    date.setDate(date.getDate() + 30); 
    setPaydatDate(date);
  }, [])

  const [email, SetEmail] = useState("");
  const [card, SetCard] = useReducer(
    (newdata: any, newDetails: any) => ({ ...newdata, ...newDetails }),
    {
      cardNumber: "",
      expirationDate: "",
      Cvc: "",
      Country: "",
      ZipCode: "",
      ownerName: "",
    }
  );

  //month", "halfyear", "year"
  const [paydScheduling, SetPaydScheduling] = useState<"month" | "halfyear" | "year">("year");
  const [cost, SetCost] = useState((props?.tiles ?? 0) * (props?.tileCost ?? 0));
  const [fragments, SetFragments] = useState([]);

  const [cost1, SetCost1] = useState(0);
  const [cost6, SetCost6] = useState(0);
  const [cost12, SetCost12] = useState(0);

  const { error: landsError, loadMyLands, CreateOrUpdateLand, RemoveLand, ToggleLandActivity, lands } = useLands({
    onCompleted: (operation: string, data: any) => {
      
    }
  })

  const TryCreateLand = () => {
    
    if (!fragments || fragments.length == 0) {
      return;
    }
  
    CreateOrUpdateLand({
      variables: {
        input: {
          fragments: fragments,
          paydScheduling: paydScheduling,
          cost: cost,
        }
      }
    })

  }

  useEffect(() => {
    if (mode == "VerifyPhone") {
      SetError(
        "The number you provided is not valid, please try again. For help, contact our help center at useland.com"
      );
    }
  }, [mode]);

  useEffect(() => {}, [card]);

  useEffect(() => {
    let com1 = presets?.find((preset: any) => preset.name == "comission1")
    let com6 = presets?.find((preset: any) => preset.name == "comission6")
    let com12 = presets?.find((preset: any) => preset.name == "comission12")

    if (com1) {
      SetCost1(com1?.value ?? 0)
    }
    if (com6) {
      SetCost6(com6?.value ?? 0)
    }
    if (com12) {
      SetCost12(com12?.value ?? 0)
    }
  }, [presets]);

  useEffect(() => {
    if (step == 2) {
      SetPaydScheduling("year");
      setTotalCost(props.tiles * props.tileCost * cost12 * 12)
    }
  }, [step]);

  return props?.open ? (
    <>
      {step == 1 ? (
        Step1({
          ...props,
          cost1,
          email: profile?.email ?? "",
          firstPurchase: props?.firstPurchase,
          title: "Choose a pieces of land",
          onNextScreen: () => {
            SetStep(2);
          },
          onClose: () => {
            SetStep(1);
            if (props.onClose) props.onClose();
          },
        })
      ) : (
        <></>
      )}

      {step == 2 ? (
        Step2({
          ...props,
          cost1,
          cost6,
          cost12,
          paydatDate30: paydatDate30,
          email: profile?.email ?? "",
          firstPurchase: props?.firstPurchase,
          totalCost: totalCost,
          title:
            props?.firstPurchase == true
              ? "Try USELANDS for free"
              : "Subscription period",
          onNextScreen: () => {
            SetStep(3);
          },
          selected: paydScheduling,
          SetCheckedElement: (paymentType: "month" | "halfyear" | "year", costOfPeriod: number) => {
            SetPaydScheduling(paymentType);
            setTotalCost(costOfPeriod)
          },
          email: email,
          OnChangeEmail: (value: string) => {
            SetEmail(email);
          },
          onClose: () => {
            SetStep(1);
            if (props.onClose) props.onClose();
          },
        })
      ) : (
        <></>
      )}

      {step === 3 ? (
        Step3({
          ...props,
          email: profile?.email ?? "",
          title:
            props?.firstPurchase == true
              ? "Try USELANDS for free"
              : "Payment method",
          onNextScreen: () => {
            SetStep(props?.firstPurchase == true ? 4 : 5);
          },
          selected: paydScheduling,
          SetCheckedElement: (paymentType: "month" | "halfyear" | "year") => {
            SetPaydScheduling(paymentType);
          },
          card: card,
          onChangeCard: (property: string, value: string) => {
            card[property] = value;
            SetCard(card);
          },
          onClose: () => {
            SetStep(1);
            if (props.onClose) props.onClose();
          },
        })
      ) : (
        <></>
      )}

      {step == 4 ? (
        Step4({
          ...props,
          email: profile?.email ?? "",
          title: "Try USELANDS for free",
          totalCost: totalCost,
          paydatDate30: paydatDate30,
          onNextScreen: () => {
            SetStep(5);
          },
          onClose: () => {
            SetStep(1);
            if (props.onClose) props.onClose();
          },
        })
      ) : (
        <></>
      )}

      {step == 5 ? (
        Step5({
          ...props,
          title:
            props?.firstPurchase == true
              ? "Welcome to Uselands"
              : "Congratulations!",
          onNextScreen: () => {},
          onClose: () => {
            SetStep(1);
            if (props.onClose) props.onClose();
          },
        })
      ) : (
        <></>
      )}
    </>
  ) : (
    <></>
  );
};

export default PurchaseForm;
